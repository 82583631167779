import styled from "styled-components";
import React, { useState } from "react";
import Box, { BoxProps } from "../box/Box";
import { ScrollSnapper } from "./ScrollSnapper";

const ScrollSnapperStyles = styled.div`
    .ScrollSnapper {
        display: flex;
        scroll-snap-type: x mandatory;
        overflow-x: scroll;
        scroll-behavior: smooth;
        scrollbar-width: none;
        position: relative;
        min-height: 375px;
    }

    .ScrollSnapper > * {
        width: 100%;
        flex-shrink: 0;
        scroll-snap-align: center;
        scroll-snap-stop: always;
    }
`

const Slide = styled.div`
    height: 100%;
    > * {
        object-fit: cover;
        object-position: center;
        display: block;
        height: 100% !important;
    }
`;

interface Props extends BoxProps {
    dotColor?: string;
    activeDotColor?: string;
}

const Slider: React.FC<React.PropsWithChildren<Props>> = ({activeDotColor = "grays.5", dotColor = "grays.2", children, ...rest}) => {
    const [currentIndex, setIndex] = useState(0);

    return (
        <Box {...rest} position="relative" width="100%" minHeight={375} overflow="hidden">
            <ScrollSnapperStyles>
                <ScrollSnapper index={currentIndex} onIndexChange={setIndex}>
                    {React.Children.map(children, (child, i) => {
                        return <Slide key={i}>{child}</Slide>;
                    })}
                </ScrollSnapper>
            </ScrollSnapperStyles>
            <Box display="flex" justifyContent="center" alignItems="center" py={2}>
                {React.Children.map(children, (child, i) => (
                    <Box
                        key={i}
                        bg={i === currentIndex ? activeDotColor : dotColor}
                        onClick={() => setIndex(i)}
                        width="10px"
                        height="10px"
                        borderRadius="50%"
                        m={3}
                        style={{ cursor: "pointer" }}
                    />
                ))}
            </Box>
        </Box>
    );
}

export default Slider;