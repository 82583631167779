import React, { ReactNode } from "react";
import Text from "@onnit-js/ui/components/text";
import ImageContentPortrait from "@onnit-js/ui/components/module/ImageContentPortrait";
import Box from "@onnit-js/ui/components/box/Box";
import AddToCartButton from "./AddToCartButton";

interface UpSellProps {
    pid: number;
    img: ReactNode;
    description: ReactNode;
    subtext?: ReactNode;
    title: ReactNode;
    price: string;
    msrp?: string;
    textColor?: string;
    buttonColor?: string;
    buttonTextColor?: string;
    inStock?: boolean;
    reverse?: boolean;
    showPercentageSaved?: boolean;
    showDollarsSaved?: boolean;
    discountColor?: string;
    headingLevel?: string;
}

interface DisplayPriceProps {
    price: string;
    msrp: string;
    showPercentageSaved: boolean;
    showDollarsSaved: boolean;
    discountColor: string;
    textColor: string;
}

export const DisplayPrice = ({
    price,
    msrp,
    showPercentageSaved,
    showDollarsSaved,
    discountColor,
    textColor,
}: DisplayPriceProps) => {
    let floatPrice = Number(price.replace(/[^0-9\.-]+/g, ""));
    let floatMsrp = Number(msrp.replace(/[^0-9\.-]+/g, ""));

    if (floatPrice && floatMsrp && floatPrice < floatMsrp) {
        let priceDifference = floatMsrp - floatPrice;
        let discountOff = Math.round((priceDifference / floatMsrp) * 100);
        let discountText = "";
        let showDiscount = false;

        if ((showPercentageSaved || showDollarsSaved) && discountOff > 0) {
            if (showPercentageSaved) {
                discountText = discountOff + "% Off";
            } else {
                discountText = "You save $" + Math.round(priceDifference).toString();
            }
            showDiscount = true;
        }

        return (
            <Box tabIndex={-1}>
                <Text
                    as="span"
                    fontWeight="light"
                    typeStyle="copy05"
                    textDecoration="line-through"
                    color={textColor}
                    display="inline"
                >
                    <Text as="span" color={textColor} className="sr-only">
                        Retail Price
                    </Text>
                    {msrp}
                </Text>{" "}
                <Text as="span" pl={[3, 4]} typeStyle="copy05" color={textColor} display="inline">
                    <Text as="span" color={textColor} className="sr-only">
                        Sale Price Price
                    </Text>
                    {price}
                </Text>
                {showDiscount && (
                    <>
                        <Text
                            as="span"
                            pl={[3, 4]}
                            typeStyle="copy05"
                            color={discountColor}
                            display="inline"
                        >
                            <Text as="span" color={textColor} className="sr-only">
                                Discount
                            </Text>
                            {discountText}
                        </Text>
                    </>
                )}
            </Box>
        );
    }
    return <>{price}</>;
};

function UpSell({
    pid,
    price,
    img,
    title,
    description,
    subtext,
    textColor = "white",
    buttonColor = "white",
    buttonTextColor = "black",
    inStock = true,
    reverse = false,
    msrp = "",
    showPercentageSaved = false,
    showDollarsSaved = false,
    discountColor = "grays.5",
    headingLevel = "h4"
}: UpSellProps) {
    return (
        <ImageContentPortrait
            reverse={reverse}
            my={[6, 6, 6, 8]}
            image={img}
            content={
                <Box display="flex" height="100%" flexDirection="column" justifyContent="center">
                    <Box>
                        <Text as={headingLevel} color={textColor} typeStyle="copyDisplay01" tabIndex={0}>
                            {title}
                        </Text>
                        <Text as="p" typeStyle="copyHero02" color={textColor} mt={[4, 4, 6]} mb={[2, 0]} tabIndex={0}>
                            {description}
                        </Text>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection={["column-reverse", "column-reverse", "row"]}
                        alignItems={["auto", "auto", "center"]}
                        flex={[0]}
                        mt={[4, 4, 6]}
                    >
                        {inStock ? (
                            <AddToCartButton
                                color={buttonColor}
                                textColor={buttonTextColor}
                                configs={[
                                    {
                                        product_id: pid,
                                        quantity: 1,
                                        subscription_interval: null,
                                    },
                                ]}
                            />
                        ) : (
                            <Box bg="grays.5" p={4} borderRadius={4}>
                                <Text color="grays.1" fontWeight="bold" textAlign="center" fontSize={1}>
                                    Out of Stock
                                </Text>
                            </Box>
                        )}

                        <Text typeStyle="copy05" my={[4, 0]} mx={[0, 4]} color={textColor} tabIndex={0}>
                            <DisplayPrice
                                textColor={textColor}
                                discountColor={discountColor}
                                price={price}
                                msrp={msrp}
                                showPercentageSaved={showPercentageSaved}
                                showDollarsSaved={showDollarsSaved}
                            />
                            {subtext && (
                                <Text
                                    typeStyle="copy05"
                                    fontSize={["11px", "10px"]}
                                    color={textColor}
                                >
                                    {subtext}
                                </Text>
                            )}
                        </Text>
                    </Box>
                </Box>
            }
        />
    );
}

export default UpSell;
