import React, { Fragment } from "react";
import Box, {BoxProps} from "@onnit-js/ui/components/box/Box";
import Text from "@onnit-js/ui/components/text/Text";

interface Props extends BoxProps {
    breadcrumbs: { slug: string; name: string }[];
}

export default function ({ breadcrumbs, ...rest }: Props) {
    return (
        <Box {...rest}>
            {breadcrumbs.map((x, i, arr) => (
                <Fragment key={x.slug}>
                    <Text as="a" href={`/${x.slug}`} display="inline-block" color="grays.4" fontSize={1}>
                        {x.name}
                    </Text>
                    {i < arr.length - 1 && (
                        <Text px={1} display="inline-block" color="grays.4" fontSize={1}>
                            /
                        </Text>
                    )}
                </Fragment>
            ))}
        </Box>
    );
}
