import React from "react";
import Icon from "../icon/Icon";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import Box, { BoxProps } from "../box/Box";
import useResponsiveProp from "../../hooks/useResponsiveProp";

interface Props extends BoxProps {
    rating: number;
    emptyStarColor?: string;
    color?: string;
    size?: number | number[];
}

const StarRating = ({
    rating,
    color = "sunwashGold",
    emptyStarColor = "black",
    size,
    style,
    ...rest
}: Props) => {
    if (rating < 0 || rating > 5) {
        console.error('Star rating must be a number between 0 and 5');
        return null;
    }

    const _sizes = useResponsiveProp(size);

    ///round to nearest .5
    let numStars = parseFloat((Math.round(rating * 2) / 2).toFixed(1));

    const hasHalfStar = numStars - Math.floor(numStars) == 0.5;
    const wholeStars = Array(Math.floor(numStars)).fill(0);
    const emptyStars = Array((5 - wholeStars.length) - (hasHalfStar ? 1 : 0)).fill(0);

    return (
        <Box
            display="inline-block"
            {...rest}
            style={{ cursor: rest.onClick ? "pointer" : "initial", ...style }}
        >
            <Box display="grid" gridTemplateColumns="1" gridTemplateRows="1">
                <Box gridColumn="1" gridRow="1" zIndex={1}>
                    {Array.from(Array(5)).map((_, i) => (
                        <Icon
                            key={`reg${i}`}
                            color={emptyStarColor}
                            icon={FaRegStar}
                            size={_sizes}
                            aria-hidden="true"
                        />
                    ))}
                </Box>
                <Box gridColumn="1" gridRow="1" zIndex={2}>
                    {wholeStars.map((_, i) => (
                        <Icon key={i} color={color} icon={FaStar} size={_sizes} aria-hidden="true" />
                    ))}
                    {hasHalfStar && (
                        <Icon color={color} icon={FaStarHalfAlt} size={_sizes} aria-hidden="true" />
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default StarRating;
