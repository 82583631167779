import React from "react";
import BazaarVoiceStarRating from "../BazaarVoiceStarRating";
import Text from "@onnit-js/ui/components/text";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import usePageData from "../../../hooks/usePageData";
import Breadcrumbs from "../Breadcrumbs";
import StickyHeader from "../StickyHeader";
import Product from "@onnit-js/ui/@types/interfaces/product/BuyBoxProduct";
import GalleryBuyAreaLayout from "./GalleryBuyAreaLayout";

interface Props {
    onProductChange?: (product: Product) => void;
    buyAreaChild?: React.ReactElement;
    stickyHeaderBtnText?: string;
    nameAsElement?: string;
}

const DefaultProductPageTop: React.FC<React.PropsWithChildren<Props>> = ({
     children,
     buyAreaChild,
     nameAsElement = "span"
 }) => {
    const onnitContext = useOnnitContext();
    const {pageData} = usePageData();

    if (!pageData || !onnitContext) return null;

    return (
        <>
            <StickyHeader/>
            <GalleryBuyAreaLayout
                column1={children}
                column2={
                    <>
                        <Breadcrumbs breadcrumbs={pageData.breadcrumbs} mb={4}/>

                        <Text
                            fontSize={8}
                            fontWeight="bold"
                            fontStyle="italic"
                            mb={2}
                            as={nameAsElement}
                            dangerouslySetInnerHTML={{__html: pageData.name}}
                        />

                        <BazaarVoiceStarRating product={pageData.products[0]}/>

                        <Text
                            my={6}
                            typeStyle="default"
                            color="grays.6"
                            dangerouslySetInnerHTML={{__html: pageData.description}}
                        />

                        {buyAreaChild}
                    </>
                }/>
        </>
    );
}

export default DefaultProductPageTop;
