import React from "react";
import {useExperiment} from "../../../hooks/useExperiment";
import Text from "../../text";

// GO Exp: risk free text
export const RiskFreeLinkText: React.FC<React.PropsWithChildren<{}>> = () => {
    const variant = useExperiment("FqbhVihgTTmmKWrJzz4vxA");
    // This shows on all variants, 1 and 2
    if (variant !== undefined) {
        return (
            <Text my={1} display="block">
                Try it worry free, cancel anytime.
            </Text>
        );
    }

    return null;
};