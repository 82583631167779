import React, { useRef, useReducer } from "react";
import Media from "@onnit-js/ui/components/media";
import useOnnitContext from "../../hooks/useOnnitContext";
import Box, { BoxProps } from "../box/Box";
import Text from "../text/Text";
import TextField from "../form/textfield/TextField";
import CheckBox from "../form/toggle/CheckBox";
import Button from "../button/Button";
import { useForm, Controller } from "react-hook-form";
import { string, object, boolean, ref } from "yup";
import MessageBox, { MessageLevelEnum } from "../notification/MessageBox";
import ReCAPTCHA from "react-google-recaptcha";
import { yupResolver } from "@hookform/resolvers/yup";

interface Props extends BoxProps {
    location: string;
    includePasswordFields?: boolean;
    includeEmailConfirmation?: boolean;
    successMessage?: string;
    onSuccess?: () => void;
    onClose: () => void;
}

interface FormValues {
    firstname: string;
    lastname: string;
    email_address: string;
    email_address2?: string;
    password?: string;
    confirmation?: string;
    newsletter: boolean;
}

interface CsrfTokenArray {
    _CSRF_TOKEN: string;
    _CSRF_INDEX: string;
}

function makeFormSchema(includePasswordFields: boolean, includeEmailConfirmation: boolean) {
    const schema: any = {
        firstname: string().label("First name").required().min(3),
        lastname: string().label("Last name").required().min(3),
        email_address: string().label("Email").required().email("Invalid email"),
        newsletter: boolean(),
    };

    if (includeEmailConfirmation) {
        schema.email_address2 = string()
            .label("Email Confirmation")
            .required()
            .email("Invalid email")
            .oneOf([ref("email_address")], "Email addresses must match");
    }

    if (includePasswordFields) {
        schema.password = string().label("Password").required().min(6);
        schema.confirmation = string()
            .label("Password")
            .required()
            .min(6)
            .oneOf([ref("password")], "Passwords must match");
    }

    return yupResolver(object().shape(schema));
}

function makeRequestPayload(
    values: FormValues,
    recaptchaToken: string,
    location: string,
    csrfTokenArray: CsrfTokenArray,
): FormData {
    const formData = new FormData();
    formData.set("action", "create_account_process");
    formData.set("firstname", values.firstname);
    formData.set("lastname", values.lastname);
    formData.set("email_address", values.email_address);
    formData.set("email_address2", values.email_address2 ?? values.email_address);
    formData.set("g-recaptcha-response", recaptchaToken);
    formData.set("is_create_account", "1");
    formData.set("newsletter", values.newsletter ? "1" : "0");
    formData.set("lead_source_new", "onnit:favorites");
    formData.set("_CSRF_TOKEN", csrfTokenArray._CSRF_TOKEN);
    formData.set("_CSRF_INDEX", csrfTokenArray._CSRF_INDEX);

    if (values.password && values.confirmation) {
        formData.set("password", values.password);
        formData.set("confirmation", values.confirmation);
    }
    if (values.newsletter) {
        window.ONNIT?.googleTagManager?.newsletterRegistered(location);
    }

    return formData;
}

type Status = "initial" | "submitting" | "success" | "error";

interface State {
    status: Status;
    error?: string;
}

interface Action {
    type: "reset" | "submit" | "success" | "error";
    error?: string;
}

const initialState: State = {
    status: "initial",
    error: undefined,
};

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case "reset":
            return { ...initialState };
        case "submit":
            return {
                status: "submitting",
                error: undefined,
            };
        case "success":
            return {
                status: "success",
                error: undefined,
            };
        case "error":
            return {
                status: "error",
                error: action.error,
            };
        default:
            return { ...initialState };
    }
}

function NewCustomerRegistrationForm({
    includePasswordFields = true,
    includeEmailConfirmation = true,
    successMessage = "Your account was created successfully.",
    location,
    onSuccess,
    onClose,
    ...boxProps
}: Props) {
    const onnitContext = useOnnitContext();

    const [state, dispatch] = useReducer(reducer, initialState);
    const { handleSubmit, formState: { errors }, control } = useForm({
          resolver: makeFormSchema(includePasswordFields, includeEmailConfirmation)
    });
    const reRef = useRef<any>();

    const getCsrfTokenArray = async () =>
        fetch("/cart/create_account.php?action=getBootstrap", {
            method: "GET",
            headers: new Headers({
                "X-Requested-With": "XMLHttpRequest",
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((text) => {
                        throw new Error(text.message.toString());
                    });
                }
                return response.json();
            })
            .then((data) => {
                return data;
            });

    const onFormSubmit = async (values: any) => {
        dispatch({ type: "submit" });

        if (!reRef.current) {
            return;
        }

        // Recaptcha token
        const token = reRef.current.getValue();
        reRef.current.reset();
        if (!token) {
            console.error("Recaptcha token absent");
            dispatch({ type: "reset" });
            return;
        }

        let csrfTokenArray: CsrfTokenArray;
        try {
            csrfTokenArray = await getCsrfTokenArray();
        } catch (error: any) {
            console.error(error);
            dispatch({ type: "reset" });
            return;
        }

        const payload = makeRequestPayload(values, token, location, csrfTokenArray);

        fetch("/cart/create_account.php", {
            method: "POST",
            headers: new Headers({
                "X-Requested-With": "XMLHttpRequest",
            }),
            body: payload,
        })
            .then((response) => {
                const contentType = response.headers.get("content-type");
                const parseMethod = contentType === "application/json" ? "json" : "text";
                return response[parseMethod]().then(function (data) {
                    if (response.ok) {
                        return Promise.resolve(data);
                    } else {
                        return Promise.reject(data);
                    }
                });
            })
            .then(() => {
                dispatch({ type: "success" });
                onSuccess && onSuccess();
            })
            .catch((errors) => {
                // Only show the first error if array
                dispatch({ type: "error", error: Array.isArray(errors) ? errors[0] : errors });
            });
    };

    if (!onnitContext) {
        return null;
    }

    return (
        <Box {...boxProps}>
            {state.status === "success" ? (
                <>
                    <MessageBox level={MessageLevelEnum.SUCCESS} message={successMessage} />
                    <Button width={["100%", "auto"]} mb={[2, 0]} size="medium" onClick={onClose}>
                        Close
                    </Button>
                </>
            ) : (
                <>
                    {state.status === "error" && (
                        <MessageBox level={MessageLevelEnum.ERROR} message={state.error} />
                    )}
                    <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
                        <Controller
                            render={({ field, fieldState: { error }, }) => (
                                <TextField
                                    {...field}
                                    type="text"
                                    label="First Name"
                                    error={error?.message}
                                />
                            )}
                            name="firstname"
                            control={control}
                            defaultValue=""
                        />

                        <Controller
                            render={({ field, fieldState: { error }, }) => (
                                <TextField
                                    {...field}
                                    type="text"
                                    label="Last Name"
                                    error={error?.message}
                                />
                            )}
                            name="lastname"
                            control={control}
                            defaultValue=""
                        />

                        <Controller
                            render={({ field, fieldState: { error }, }) => (
                                <TextField
                                    {...field}
                                    type="email"
                                    label="Email"
                                    error={error?.message}
                                />
                            )}
                            name="email_address"
                            control={control}
                            defaultValue=""
                        />

                        {includeEmailConfirmation && (
                            <Controller
                                render={({ field, fieldState: { error }, }) => (
                                    <TextField
                                        {...field}
                                        type="email"
                                        label="Email Confirmation"
                                        error={error?.message}
                                    />
                                )}
                                name="email_address2"
                                control={control}
                                defaultValue=""
                            />
                        )}

                        {includePasswordFields && (
                            <>
                                <Controller
                                    render={({ field, fieldState: { error }, }) => (
                                        <TextField
                                            {...field}
                                            type="password"
                                            label="Password"
                                            error={error?.message}
                                        />
                                    )}
                                    name="password"
                                    control={control}
                                    defaultValue=""
                                />
                                <Controller
                                    render={({ field, fieldState: { error }, }) => (
                                        <TextField
                                            {...field}
                                            name="confirmation"
                                            type="password"
                                            label="Confirmation"
                                            error={error?.message}
                                        />
                                    )}
                                    name="confirmation"
                                    control={control}
                                    defaultValue=""
                                />
                            </>
                        )}

                        <Controller
                            name="newsletter"
                            control={control}
                            defaultValue={true}
                            render={({ field, fieldState: { error }, }) => (
                                <CheckBox
                                    {...field}
                                    checked={field.value}
                                    onChange={(e) => {
                                        field.onChange(e.target.checked);
                                    }}
                                    name="newsletter"
                                    error={error?.message}
                                    label={(
                                        <Box>
                                            <Text
                                                mt={1}
                                                color="grays.5"
                                                typeStyle="caption02"
                                            >
                                                Yes, I want to receive offers and updates
                                                from Onnit and other related brands via email and online
                                                marketing. I have read and acknowledge Onnit's{" "}
                                                <Text as="a" display="inline" href="/privacy-policy/" target="_blank">Privacy Policy</Text> and{" "}
                                                <Text as="a" display="inline" href="/financial-incentives-notice/" target="_blank">Notice of Financial Incentive</Text>.
                                                I understand I may unsubscribe from promotional emails at any time.*
                                            </Text>
                                            <Text
                                                mt={2}
                                                color="grays.5"
                                                typeStyle="caption02"
                                                fontStyle="italic"
                                                style={{
                                                    fontSize: "12px"
                                                }}
                                            >
                                                *If you unsubscribe, you may still receive non-marketing or transactional messages from us.
                                            </Text>
                                        </Box>
                                    )}
                                />)
                            }
                        />

                        <Box minHeight="78px">
                            <Media query="(min-width: 376px)">
                                {(matches) => (
                                    matches && <ReCAPTCHA
                                        sitekey={onnitContext.api_key.recaptcha_public_key}
                                        size={matches ? "normal" : "compact"}
                                        ref={reRef}
                                    />
                                )}
                            </Media>
                        </Box>

                        <Box py={3}>
                            <Button
                                width={["100%", "auto"]}
                                mb={[2, 0]}
                                size="medium"
                                type="submit"
                                disabled={state.status === "submitting"}
                            >
                                {state.status === "submitting" ? "Sending..." : "Sign up"}
                            </Button>
                        </Box>
                    </form>
                </>
            )}
        </Box>
    );
}

export default NewCustomerRegistrationForm;
