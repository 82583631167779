import React from "react";
import Box from "../../../box/Box";
import { RadioButton, RadioGroup } from "../../../form/toggle/RadioGroup";
import Text from "../../../text/Text";
import SubscriptionInfoModal from "../../shared/SubscriptionInfoModal";
import SelectField from "../../../form/selectfield/SelectField";
import QuantitySelect from "./QuantitySelect";
import EnumUtil from "../../../../utils/EnumUtil";
import {SubscriptionIntervalEnum} from "../../../../@types/enums/SubscriptionInterval";
import Product from "../../../../@types/interfaces/product/BuyBoxProduct";
import CostPerServing from "../../shared/CostPerServing";
import CostPerCount from "../../shared/CostPerCount";
import {ProductCategory} from "../../../../@types/enums/ProductCategory";
import {RiskFreeLinkText} from "../../shared/RiskFreeLinkText";


interface Props {
    doSubscribe: boolean;
    setDoSubscribe: (x: boolean) => void;
    subscriptionInterval: number;
    setSubscriptionInterval: (x: number) => void;
    quantity: number;
    setQuantity: (x: number) => void;
    product: Product;
    subscriptionPrice: number;
    subscriptionPercentOff?: number;
    subscriptionRebillDiscount?: number;
    saleText: string;
    isOnSale: boolean;
    subscriptionIntervalDefault: number;
    isDigital: boolean;
}

const SubscriptionEligible = ({
    doSubscribe,
    quantity,
    setQuantity,
    setDoSubscribe,
    product,
    subscriptionPrice,
    saleText,
    isOnSale,
    subscriptionIntervalDefault,
    subscriptionInterval,
    subscriptionPercentOff = 15,
    subscriptionRebillDiscount = 15,
    setSubscriptionInterval,
    isDigital,
}: Props) => {
    const subscriptionIntervals = EnumUtil.getNumericValues(SubscriptionIntervalEnum);
    const isSupplementsOrFoods =
        product.meta.category === ProductCategory.FOODS ||
        product.meta.category === ProductCategory.SUPPLEMENTS;
    const cartQuantityMax = product.meta.category === ProductCategory.FITNESS ? 2 : product.cart_quantity_max;

    return (
        <Box>
            <RadioGroup
                labelPosition="left"
                value={doSubscribe ? "subscribe" : "single"}
                onChange={({ target: { value } }) => {
                    setDoSubscribe(value === "subscribe");
                }}
            >
                <RadioButton value="subscribe">
                    <Box display="flex" alignItems="center" mb={1}>
                        <Text color="grays.4">Subscribe &amp; Save</Text>
                        <SubscriptionInfoModal />
                    </Box>

                    <RiskFreeLinkText />

                    <p className="sr-only">Normally,</p>
                    <Text display="inline-block" textDecoration="line-through" color="grays.4">
                        {product.price_msrp}
                    </Text>
                    <p className="sr-only">Your price today,</p>
                    <Text display="inline-block" fontWeight="bold" ml={1}>
                        ${subscriptionPrice.toFixed(2)}
                    </Text>

                    <Text display="inline-block" fontWeight="bold" color="grays.4" ml={2}>
                        {product.is_subscription_discount_applicable
                            ? `${subscriptionPercentOff}% off`
                            : saleText}
                    </Text>
                    {!product.is_subscription_discount_applicable && (
                        <Text display="block" fontSize={0} color="grays.4" mt={1}>
                            Subscription rebills at {subscriptionRebillDiscount}% off MSRP
                        </Text>
                    )}
                    {isSupplementsOrFoods && (
                        <>
                            <CostPerServing
                                price={product.price_subscription_raw?.toString()}
                                totalServings={product.product_servings_total}
                                display="none"
                                mt={1}
                            />
                            <CostPerCount
                                price={product.price_subscription_raw?.toString()}
                                count={product.product_size}
                                unitOfMeasure={product.product_serving_unit_of_measure}
                                display="none"
                                mt={1}
                            />
                        </>
                    )}
                </RadioButton>
                <RadioButton value="single">
                    <Text color="grays.4" mb={1}>
                        Purchase this time only
                    </Text>
                    {isOnSale && (
                        <Text display="inline-block" textDecoration="line-through" color="grays.4" mr={2}>
                            {product.price_msrp}
                        </Text>
                    )}
                    <Text display="inline-block" fontWeight="bold">
                        {product.price}
                    </Text>
                    {isOnSale && (
                        <Text display="inline-block" fontWeight="bold" color="grays.4" ml={2}>
                            {saleText}
                        </Text>
                    )}
                    {isSupplementsOrFoods && (
                        <>
                            <CostPerServing
                                price={product.price_raw}
                                totalServings={product.product_servings_total}
                                display="none"
                                mt={1}
                            />
                            <CostPerCount
                                price={product.price_raw}
                                count={product.product_size}
                                unitOfMeasure={product.product_serving_unit_of_measure}
                                display="none"
                                mt={1}
                            />
                        </>
                    )}
                </RadioButton>
            </RadioGroup>

            {doSubscribe && (
                <SelectField
                    label="Deliver every"
                    value={subscriptionInterval.toString()}
                    onChange={(e) => setSubscriptionInterval(Number(e.target.value))}
                    flex="1 1 auto"
                >
                    {subscriptionIntervals.map((value) => (
                        <option key={value} value={value.toString()}>
                            {`${value.toString()} Days${
                                value === subscriptionIntervalDefault ? " (most common)" : ""
                            }`}
                        </option>
                    ))}
                </SelectField>
            )}
            {!isDigital && (
                <QuantitySelect
                    cartQuantityMax={cartQuantityMax}
                    value={quantity.toString()}
                    onChange={(e) => setQuantity(Number(e.target.value))}
                    flex="1 0 auto"
                />
            )}
        </Box>
    );
};

export default SubscriptionEligible;
