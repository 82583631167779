import React from "react";
import Text from "../../../text/Text";
import ReadOnly from "../../../form/field/ReadOnly";
import Box from "../../../box/Box";
import QuantitySelect from "./QuantitySelect";
import Product from "../../../../@types/interfaces/product/BuyBoxProduct";
import {ProductCategory} from "../../../../@types/enums/ProductCategory";

interface Props {
    quantity: number;
    setQuantity: (x: number) => void;
    product: Product;
    isOnSale: boolean;
    isDigital: boolean;
    saleText?: string;
}

const NonSubscriptionEligible = ({
    isOnSale,
    saleText,
    product,
    isDigital,
    quantity,
    setQuantity,
}: Props) => {
    const cartQuantityMax = product.meta.category === ProductCategory.FITNESS ? 2 : product.cart_quantity_max;

    return (
        <Box display="flex" justifyContent="space-between">
            <ReadOnly
                flex="1 1 auto"
                label="Price"
                value={
                    <>
                        {isOnSale && (
                            <>
                                <p className="sr-only">Normally,</p>
                                <Text
                                    display="inline-block"
                                    textDecoration="line-through"
                                    color="grays.4"
                                    mr={1}
                                >
                                    {product.price_msrp}
                                </Text>
                                <p className="sr-only">Your price today,</p>
                            </>
                        )}
                        <Text display="inline-block" fontWeight="bold">
                            {product.price}
                        </Text>
                        {isOnSale && (
                            <Text display="inline-block" fontWeight="bold" color="grays.4" ml={2}>
                                {saleText}
                            </Text>
                        )}
                    </>
                }
            />

            {!isDigital && (
                <QuantitySelect
                    cartQuantityMax={cartQuantityMax}
                    value={quantity.toString()}
                    onChange={(e) => setQuantity(Number(e.target.value))}
                    flex="1 1 auto"
                    ml={isDigital ? 0 : 2}
                />
            )}
        </Box>
    );
};

export default NonSubscriptionEligible;
