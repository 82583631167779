import React, { ReactNode } from "react";
import Text, { TextProps } from "./Text";
import DaggerSymbol from "./DaggerSymbol";

interface SupProps extends TextProps {
    variant?: "dagger" | "reg" | "trade";
}

const Sup: React.FC<React.PropsWithChildren<SupProps>> = ({
    variant = "dagger",
    color = "inherit",
    fontSize = ["0.4em", "0.4em", "0.55em"],
    children,
    ...rest
}) => {
    let symbol: ReactNode = null;

    switch (variant) {
        case "dagger":
            symbol = <DaggerSymbol />;
            break;
        case "reg":
            symbol = <>&reg;</>;
            break;
        case "trade":
            symbol = <>&trade;</>;
            break;
        default:
            symbol = null;
    }

    return (
        <Text
            as="sup"
            display="inline"
            className="oui-sup"
            fontSize={fontSize}
            color={color}
            verticalAlign="top"
            aria-hidden="true"
            {...rest}>
            {children ? children : symbol}
        </Text>
    );
};

export default Sup;
