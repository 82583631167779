import React, { useEffect, useState } from "react";
import {ProductStatus} from "../../../@types/enums/ProductStatus";
import { getProductStatus } from "../utils";
import Box from "../../box/Box";
import VariantSelector from "./VariantSelector";
import AppareInStockView from "./ApparelInStockView";
import OOSView from "../shared/OOSView";
import DiscontinuedView from "../shared/DiscontinuedView";
import { BuyBoxProps } from "../BuyBox";
import {getUrlParameter} from "../../../utils/utils";
import Product from "../../../@types/interfaces/product/BuyBoxProduct";
import config from "../../../config";
import { FavoriteContainerButton } from "../shared/favorites";
import { sortBySize } from "./sizes";
import PromoDiscount, {getActivePromoDiscountConfig} from "../../buybox/shared/PromoDiscount";

function ApparelBuyBox({
    products,
    promoDiscountConfigs,
    productSelectLabel = "Product",
    freeShippingThreshold = config.FREE_SHIPPING_THRESHOLD_DEFAULT,
    onProductChange,
    productNameTransform,
    showFavoritesButton = true,
    showFooter = true,
    onSuccess,
    onError,
    buyBoxRef,
    addToBagMiddleware,
    ...rest
}: BuyBoxProps) {
    if (!products || products.length === 0) {
        console.error("Apparel BuyBox: no products provided");
        return null;
    }

    const containerProduct = products[0];

    // Presort size variants
    containerProduct.children?.forEach((colorVariant) => {
        colorVariant.children?.sort(sortBySize);
    });

    const [selectedColorVariant, setColorVariant] = useState<Product | null>(() => {
        // On mount, check if the url has a specified variant PID
        // or default to the first color variant in the container apparel product.
        const pid = getUrlParameter("variant"); // Apparel pages use variant to auto select via query param
        const colorVariants = containerProduct.children;

        if (!colorVariants) {
            console.error("Apparel BuyBox: No color variants defined.");
            return null;
        }

        // If there is a variant pid in the url set it as the selected color variant
        if (pid) {
            const match = colorVariants.find((prod) => prod.id === Number(pid));
            if (match) {
                return match;
            }
        }

        // Otherwise, set the color variant selected to the first variant in stock.
        const inStockColors = colorVariants.filter((product) => {
            return getProductStatus(product) === ProductStatus.InStock;
        });

        return inStockColors.length > 0 ? inStockColors[0] : colorVariants[0];
    });

    const [selectedSizeVariant, setSizeVariant] = useState<Product | null>(null);

    // Default to the first size variant in stock when the color variant changes
    useEffect(() => {
        const sizeVariants = selectedColorVariant?.children;
        if (sizeVariants) {
            const inStockSizes = sizeVariants.filter((color) => color.in_stock);
            setSizeVariant(inStockSizes.length > 0 ? inStockSizes[0] : sizeVariants[0]);
        }
    }, [selectedColorVariant]);

    // We only care about notifying the outside world about the color variant
    useEffect(() => {
        if (selectedColorVariant) {
            onProductChange && onProductChange(selectedColorVariant);
        }
    }, [selectedColorVariant]);

    if (!selectedSizeVariant) {
        return null;
    }

    const activePromoConfig = promoDiscountConfigs
        ? getActivePromoDiscountConfig(selectedSizeVariant.id, promoDiscountConfigs)
        : null;

    return (
        <Box ref={buyBoxRef} {...rest}>
            {activePromoConfig && <PromoDiscount promoDiscountConfig={activePromoConfig} my={4} />}

            <VariantSelector
                containerProduct={products[0]}
                selectedColorVariant={selectedColorVariant}
                selectedSizeVariant={selectedSizeVariant}
                setColorVariant={setColorVariant}
                setSizeVariant={setSizeVariant}
                onChange={setSizeVariant}
            />

            {
                {
                    [ProductStatus["InStock"]]: (
                        <AppareInStockView
                            onSuccess={onSuccess}
                            onError={onError}
                            addToBagMiddleware={addToBagMiddleware}
                            product={selectedSizeVariant}
                            freeShippingThreshold={freeShippingThreshold}
                            showFooter={showFooter}
                        >
                            {showFavoritesButton && selectedColorVariant && (
                                <FavoriteContainerButton mt={2} productId={selectedColorVariant.id} />
                            )}
                        </AppareInStockView>
                    ),
                    [ProductStatus["OutOfStock"]]: (
                        <OOSView product={selectedSizeVariant}>
                            {showFavoritesButton && selectedColorVariant && (
                                <FavoriteContainerButton mt={2} productId={selectedColorVariant.id} />
                            )}
                        </OOSView>
                    ),
                    [ProductStatus["Discontinued"]]: <DiscontinuedView />,
                }[getProductStatus(selectedSizeVariant)]
            }
        </Box>
    );
}

export default ApparelBuyBox;
