import React from "react";
import Product from "@onnit-js/ui/@types/interfaces/product/BuyBoxProduct";
import StickyButtonAddToBagSimple from "./StickyButtonAddToBagSimple";
import {ProductType} from "@onnit-js/ui/@types/enums/ProductType";

export interface AddToBagSimpleStickyProps {
    products: Product[];
}

export default ({ products }: AddToBagSimpleStickyProps) => {

    if (!products) return null;

    const isApparel = (products[0].type_id === ProductType.APPAREL_CONTAINER) ?? false;
    const inStock = (products[0].in_stock) ?? false;
    const isSubscriptionEligible = (products[0].meta["subscription-eligible"] === "yes") ?? false;
    const productId = products[0].id ?? 0;

    if (isApparel || !inStock || isSubscriptionEligible || products.length > 1) return null;

    return (
        <StickyButtonAddToBagSimple
            buttonProps={{ borderWidth: "1px", borderColor: "rgb(255, 255, 255)", boxShadow: "rgb(255,255,255 / 40%" }}
            pid={productId}
        />
    );
};