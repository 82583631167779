import React from "react";
import { PageHeader as PageHeaderInterface } from "../../interfaces/SiteContent";
import {BoxProps} from "@onnit-js/ui/components/box/Box";
import PageHeader from "@onnit-js/ui/components/module/PageHeader";

interface Props extends BoxProps {
    data: PageHeaderInterface;
}

const ContentfulPageHeader = ({ data: { backgroundImage, foregroundImage, hiddenHeading } }: Props) => {
    return (
        <>
            <h1 className="sr-only">{hiddenHeading}</h1>
            <PageHeader bgImageSrc={backgroundImage.src} contentImageProps={foregroundImage}></PageHeader>
        </>
    );
};

export default ContentfulPageHeader;
