import React, { ReactNode } from 'react';
import styled from "styled-components";
import Box from "@onnit-js/ui/components/box/Box";

const Container = styled.div`
    box-sizing: border-box;
    max-width: 1920px;
    margin: 24px auto;
    @media (min-width: ${(p) => p.theme.breakpoints[2]}) {
        display: flex;
    }
`;

interface Props {
    column1: ReactNode;
    column2: ReactNode;
}

const GalleryBuyAreaLayout: React.FC<Props> = ({column1, column2}) => {
    return (
        <Container>
            <Box flex="1 1 auto">{column1}</Box>
            <Box p={[4, 4, 5]} flex="0 0 460px">{column2}</Box>
        </Container>
    );
};

export default GalleryBuyAreaLayout;