import { ThemeInterface } from "./interfaces";
import { base } from "./base";
import cloneDeep from "lodash/cloneDeep";

const dark = cloneDeep(base);

dark.colors["text"] = dark.colors.white;

export default <ThemeInterface>{
    ...dark,
    name: "dark",
    backgroundColor: dark.colors.black,
    component: {
        button: {
            bg: dark.colors.white,
            color: dark.colors.black
        },
        formfield: {
            initial: {
                borderColor: dark.colors.grays[5],
                helperTextColor: dark.colors.grays[3]
            },
            active: {
                borderColor: dark.colors.white,
                helperTextColor: dark.colors.grays[2]
            },
            error: {
                borderColor: dark.colors.gameRed,
                helperTextColor: dark.colors.gameRed
            },
            valid: {
                borderColor: "#76d100",
                helperTextColor: "#76d100"
            },
            disabled: {
                borderColor: dark.colors.grays[2],
                helperTextColor: dark.colors.grays[2]
            },
            readOnly: {
                borderColor: dark.colors.grays[2],
                helperTextColor: dark.colors.grays[2]
            }
        },
        toggle: {
            initial: {
                borderColor: dark.colors.grays[5]
            },
            active: {
                borderColor: dark.colors.sunwashGold
            }
        }
    }
};
