import React from 'react';
import Text from "@onnit-js/ui/components/text";
import Box, {BoxProps} from "@onnit-js/ui/components/box/Box";
import styled from "styled-components";

interface StarProps extends BoxProps {
    rating: number;
}

export default ({ rating, ...rest }: StarProps) => {

    const ratingText = (rating / 100 * 5).toFixed(1);
    const Stars = styled.div`
        width: ${rating}%;
    `;

    return (
        <Box py={2} {...rest} display="flex" alignContent="center">
            <Box className="stars inline">
                <div>
                    <Box className="stars-empty" aria-hidden="true" />
                    <Stars className="stars-full" aria-hidden="true" />
                </div>
            </Box>
            <Box>
                <Text as="span" className="sr-only">
                    {ratingText && rating > 0 ?
                        (
                            <>
                                {" "}out of 5 star rating
                            </>
                        ) : (
                            <>
                                {" "}Product has no rating
                            </>
                        )}
                </Text>
                <Text as="span" aria-hidden="true" display="inline" ml={1} fontSize={["12px"]}>{ratingText}</Text>
            </Box>
        </Box>
    );
};
