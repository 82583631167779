import React from "react";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import usePageData from "../../hooks/usePageData";
import ContentfulComponentMapper from "../../components/contentful/ContentfulComponentMapper";
import Box from "@onnit-js/ui/components/box/Box";
import Text from "@onnit-js/ui/components/text";
import theme from "@onnit-js/ui/components/themes/light";
import GlobalStyle from "@onnit-js/ui/components/themes/GlobalStyle";
import ThemeProvider from '@onnit-js/ui/components/themes/ThemeProvider';

function NotFound() {
    console.error("content is unexpectedly null");
    return (
        <Box>
            <Text as="h1" typeStyle="title01">
                Page Not Found
            </Text>
        </Box>
    );
}

const ContentfulPage: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = ({ children }) => {
    const onnitContext = useOnnitContext();
    const { pageData } = usePageData();

    if (!pageData || !onnitContext) return null;

    const pageContent = pageData.contentJson ? JSON.parse(pageData.contentJson) : null;
    const metaTitle = pageContent.data?.meta?.title;

    return (
        <ThemeProvider theme={theme}>
            <>
                <GlobalStyle
                    css={() => `
                        #page-content {
                            background-color: ${pageContent.data.backgroundColor ?? "transparent"};
                            background-image: ${
                                pageContent.data.backgroundImage ? `url(${pageContent.data.backgroundImage})` : "none"
                            };
                            background-repeat: ${pageContent.data.backgroundRepeat ?? "no-repeat"};
                        }                                                            
                    `}
                />
                {pageContent.data?.content ? (
                    <>
                        {metaTitle && (
                            <Text as="h1" className="sr-only">
                                {metaTitle}
                            </Text>
                        )}
                        <ContentfulComponentMapper content={pageContent.data.content} />
                        {children}
                    </>
                ) : (
                    <NotFound />
                )}
            </>
        </ThemeProvider>
    );
};

export default ContentfulPage;
