import { useEffect, useState } from "react";

export const useExperiment = (experimentId: string): string | undefined => {
    const [variant, setVariant] = useState<string | undefined>();

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (window.google_optimize !== undefined) {
                setVariant(window.google_optimize.get(experimentId));
                clearInterval(intervalId);
            }
        }, 100);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return variant;
};
