import { Faq } from "../../components/common/Faqs";

const faqs: Faq[] = [
    {
        question:
            'What is the benefit of a product like Total GUT HEALTH<span class="oui-sup">&trade;</span>?',
        answer:
            'Everyone can benefit from a product like Total GUT HEALTH<span class="oui-sup">&trade;</span>! This provides digestive enzymes (Onnit’s very own DIGESTech®) and Betaine HCL to help breakdown the foods we eat, prebiotics to feed to good bacteria, and probiotics to help balance the “bad”. This is a digestive-game changer!',
    },
    {
        question: 'Why should I take Total GUT HEALTH<span class="oui-sup">&trade;</span>?',
        answer:
            "Simply put, our daily lifestyles don’t support proper digestion; eating too fast, poor food choices, too much daily stress, etc.. A product like this provides the building blocks needed for the GI tract to perform at it’s best, and as a result, keeps you performing at your best!",
    },
    {
        question: "Is this a probiotic?",
        answer:
            'Total GUT HEALTH<span class="oui-sup">&trade;</span> is so much more than a probiotic! It contains pre and probiotics to keep the gut microbiome in tip-top shape.',
    },
    {
        question:
            'How long will it take for my body to adjust to Total GUT HEALTH<span class="oui-sup">&trade;</span>?',
        answer:
            "Some users experience immediate results. This can look like less bloating after a meal, or just smoother digestion in general.",
    },
    {
        question: "Do I need to take it everyday? When is the best time to take it?",
        answer:
            "The components are beneficial to be taken daily and it’s been designed to make that easy. Take one pack with your biggest meal of the day and you’re set!",
    },
    {
        question:
            "I don't like swallowing a lot of pills at once, can I take the capsules at different times?",
        answer:
            "While we suggest taking them together, the capsules can be taken separately throughout the day. Just be sure to take each of them with a full meal. If you don’t eat standard meals throughout the day, it may be best to take them all at once so there’s a full belly to support the capsules.",
    },
];

export default faqs;