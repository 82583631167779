import React from "react";
import Box from "../../box/Box";
import Text from "../../text/Text";
import LeftLineCTA from "../../cta/LeftLineCTA";
import nextId from "react-id-generator";
import { useInView } from "react-intersection-observer";
import { lazyOptions } from "../../image/shared";

interface Testimonial {
    imgSrc: string;
    quote: string;
    author?: string;
    title?: string;
    lazyLoad?: boolean;
    quoteColor?: string;
}

export function Testimonial({lazyLoad = true, ...props}: Testimonial) {
    const [ref, inView] = useInView(lazyOptions);

    const imageSrc = !lazyLoad ? props.imgSrc : (inView) ? props.imgSrc : undefined;

    return (
        <Box display={["block", "flex"]} gridRow="1 / span 1" gridColumn="1" ref={ref}>
            <Box
                position="relative"
                display="flex"
                flex="1"
                justifyContent={["center", "flex-end"]}
                alignItems="flex-start"
                mx={3}>
                <div className="dashContainer">
                    <Box>
                        <svg viewBox="0 0 88.3 135">
                            <path
                                id={nextId("left-")}
                                d="M88.3,0l-15,0c-8.5,0-13.8,6.3-15.2,9.2L3.7,102.3c-5.5,9.5-8.5,32.6,18.4,32.6l66.2,0.1V0L88.3,0z"
                            />
                        </svg>
                    </Box>
                    <div className="animatedRect" />
                    <Box>
                        <svg viewBox="0 0 85.7 134.9">
                            <path
                                id={nextId("right-")}
                                d="M62.2,0L0,0v134.9l10.8,0c3.7,0,10.9-1.5,15.4-9.2l56.4-97.2C87.9,19.4,89.1,0,62.2,0z"
                            />
                        </svg>
                    </Box>
                </div>
                <div className="imageContainer">
                    <svg width="224px" viewBox="0 0 224 135">
                        <defs>
                            <clipPath id={nextId("clip-")}>
                                <path d="M200.546174,0.0982696897 L73.3155567,0 C64.8455617,0 59.5651249,6.26670644 58.0711791,9.15519093 L3.66961174,102.27136 C-1.80603831,111.724582 -4.79554318,134.903341 22.1051607,134.903341 L149.137338,135 C152.820576,135 159.996678,133.509845 164.580155,125.848031 L220.969348,28.652864 C226.249785,19.499284 227.446878,0.0982696897 200.546174,0.0982696897" />
                            </clipPath>
                        </defs>
                        <image
                            height="100%"
                            width="100%"
                            preserveAspectRatio="xMinYMin slice"
                            xlinkHref={imageSrc}
                            clipPath="url(#clip)"
                        />
                    </svg>
                </div>
            </Box>
            <Box flex="0 1 60%" py={4} px={[4, 6]}>
                <Box maxWidth="620px" display="flex" flexDirection="column">
                    <Text typeStyle="text02" color={props.quoteColor ?? "black"} mb={5} className="quoteContainer" dangerouslySetInnerHTML={{__html:props.quote}} />
                    {props.author && (<LeftLineCTA alignSelf="flex-end" className="citeContainer">
                        {props.author}
                        {props.title && (
                            <Text
                                fontSize={2}
                                fontWeight="light"
                                fontStyle="italic"
                                textTransform="uppercase">
                                {props.title}
                            </Text>
                        )}
                    </LeftLineCTA>
                    )}
                </Box>
            </Box>
        </Box>
    );
}
