import React, {useState} from "react";
import Box, {BoxProps} from "@onnit-js/ui/components/box/Box";
import Text from "@onnit-js/ui/components/text";
import Modal from "@onnit-js/ui/components/modal/Modal";

const FreeShipping: React.FC<React.PropsWithChildren<React.PropsWithChildren<BoxProps>>> = () => {
    const [isOpen, setOpen] = useState(false);
    const FREE_SHIPPING_THRESHOLD_DEFAULT = window.onnit_context?.free_shipping_threshold ?? 150;

    return (
        <>
            <Box px={2}>
                <Text fontSize="13px" fontWeight="heavy" color="grays.7">
                    FREE Shipping{" "}
                    <Text
                        as="span"
                        fontSize="inherit"
                        fontWeight="heavy"
                        color="grays.7"
                        display="inline"
                        textDecoration="underline"
                        onClick={() => setOpen((state) => !state)}
                    >
                        over ${FREE_SHIPPING_THRESHOLD_DEFAULT} (USD)
                    </Text>
                    *
                </Text>
            </Box>
            <Modal title="Free Shipping" isOpen={isOpen} onRequestClose={() => setOpen((state) => !state)}>
                    <ul>
                        <li className="mb2"><Text lineHeight={5} fontWeight={"bold"}>USA:</Text>{" "}<Text lineHeight={5}>Free shipping is offered on orders with a minimum subtotal of ${FREE_SHIPPING_THRESHOLD_DEFAULT} less discounts. Free shipping is
                            only available in the contiguous U.S. and excludes Fitness and Digital items.</Text></li>
                        <li><Text lineHeight={5} fontWeight={"bold"}>Canada:</Text>{" "}<Text lineHeight={5}>USD ${window.onnit_context.canada_flat_rate} flat-rate shipping is offered on orders
                            with a minimum subtotal of USD ${window.onnit_context.canada_flat_rate_shipping_threshold} less discounts.
                            Excludes Fitness and Digital items.</Text>
                        </li>
                    </ul>
            </Modal>
        </>
    );
};

export default FreeShipping;
