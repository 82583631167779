import React from "react";
import Box, {BoxProps} from "@onnit-js/ui/components/box/Box";
import Text from "@onnit-js/ui/components/text";
import DaggerSymbol from "@onnit-js/ui/components/text/DaggerSymbol";
import usePageData from "../../../hooks/usePageData";

interface Props extends BoxProps {}

const FDAWarning: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    const {pageData} = usePageData();

    if (!pageData || pageData.hasFDAWarning === false) return null;

    return (
        <Box my={4} {...props}>
            <Text as="p" fontSize={0} color="grays.4" fontWeight={"bold"}>
                <DaggerSymbol /> THESE STATEMENTS HAVE NOT BEEN EVALUATED BY THE FOOD AND DRUG ADMINISTRATION.
                THIS PRODUCT IS NOT INTENDED TO DIAGNOSE, TREAT, CURE, OR PREVENT ANY DISEASE.
            </Text>
        </Box>
    );
};

export default FDAWarning;
