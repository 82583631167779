import { StarTestimonial } from "../../components/common/FiveStarTestimonials";

const testimonials: StarTestimonial[] = [
    {
        quote:
            "I have been using Total GUT HEALTH™ and Total Human® for a few months. I really like the products. I feel more functional throughout the day, sleep better, and have less stomach bloating after eating dinner.",
        author: "Kevin",
        starsColor: "#d1b16e",
    },
    {
        quote:
            "This stuff is completely transforming. Certainly it could be a result of or a combination of other choices I've been making, but this seems to have helped many of my issues . I'll definitely be purchasing again for myself and encouraging family members to try it as well.",
        author: "Jeremy",
        starsColor: "#d1b16e",
    },
    {
        quote:
            "This product has been a life saver for me. I take this product religiously and it's really helped!",
        author: "Sam",
        starsColor: "#d1b16e",
    },
    {
        quote:
            "I love Total GUT HEALTH™. Truly makes a huge difference when I know I’m going out to eat and will be enjoying less than optimal foods, or even when I’m simply eating a big meal at home and need a little extra digestive support! Total GUT HEALTH™ is a staple in my daily wellness routine.",
        author: "Nicole",
        starsColor: "#d1b16e",
    },
    {
        quote:
            "My stomach has always been a little sensitive, and so far, I've found this to be the best thing to help get me through meals. Definitely worth every cent!!",
        author: "Liz",
        starsColor: "#d1b16e",
    },
];

export default testimonials;
