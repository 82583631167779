import React from "react";
import Text from "../../text/Text";
import Box, { BoxProps } from "../../box/Box";

interface Props extends BoxProps {
    price: string | undefined | null;
    count: number;
    unitOfMeasure: string;
}

export default function CostPerCount({ price, count, unitOfMeasure, ...rest }: Props) {
    if (!price) return null;

    return (
        <Box className="pricePerCount" {...rest}>
            <Text display="block" color="grays.4" fontSize={0}>
                ${(parseFloat(price) / count).toFixed(2)}
                <span className="sr-only">Per</span>
                <span aria-hidden="true"> / </span>
                {unitOfMeasure}
            </Text>
        </Box>
    );
}
