import React, { useCallback, useEffect, useState } from "react";
import Button, { ButtonProps } from "../../../button/Button";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import Icon from "../../../icon/Icon";

interface FavoriteButtonProps extends ButtonProps {
    productId: number;
    iconOnly?: boolean;
    defaultSelected?: boolean;
    addProduct: (productId: number) => Promise<void>;
    removeProduct: (productId: number) => Promise<void>;
}

export function FavoriteButton({
    productId,
    iconOnly,
    defaultSelected = false,
    addProduct,
    removeProduct,
    ...rest
}: FavoriteButtonProps) {
    const [isLoading, setLoading] = useState(false);
    const [isSelected, setSelected] = useState(defaultSelected);
    const [annoucement, setAnnoucment] = useState<string | null>(null);

    useEffect(() => {
        setSelected(defaultSelected);
        setLoading(false);
    }, [defaultSelected]);

    const btnLabel = `${isSelected ? "Remove from" : "Add to"} favorites`;

    const add = useCallback(
        async (productId: number): Promise<void> => {
            // Optimistic update.
            setSelected(true);
            setLoading(true);
            try {
                await addProduct(productId);
                setAnnoucment("Product added to favorites");
            } catch (err) {
                // Rollback optimistic update
                setSelected(false);
                err && console.log(err);
            } finally {
                setLoading(false);
            }
        },
        [addProduct]
    );

    const remove = useCallback(
        async (productId: number): Promise<void> => {
            // Optimistic update.
            setSelected(false);
            setLoading(true);
            try {
                await removeProduct(productId);
                setAnnoucment("Product removed from favorites");
            } catch (err) {
                // Rollback optimistic update
                setSelected(true);
                err && console.log(err);
            } finally {
                setLoading(false);
            }
        },
        [removeProduct]
    );

    const handleClick = useCallback(() => {
        // Used to prevent double click without visually disabling the button.
        if (isLoading) return;
        isSelected ? remove(productId) : add(productId);
    }, [productId, isSelected, isLoading, add, remove]);

    return (
        <>
            <div role="alert" className="sr-only">
                <p>{annoucement}</p>
            </div>

            <Button fill="text" width={1} onClick={handleClick} {...rest}>
                {iconOnly && <span className="sr-only">{btnLabel}</span>}
                <Icon
                    color={isSelected ? "gameRed" : "black"}
                    icon={isSelected ? IoMdHeart : IoMdHeartEmpty}
                    aria-hidden="true"
                    mr={iconOnly ? 0 : 1}
                    title="Heart Icon"
                />
                {!iconOnly && btnLabel}
            </Button>
        </>
    );
}

export default FavoriteButton;
