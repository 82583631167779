import React, {ReactNode} from "react";
import Media from "@onnit-js/ui/components/media";
import { BoxProps } from "../../box/Box";
import useRovingIndex from "../../../hooks/useRovingIndex";
import { Mobile } from "./Mobile";
import { Desktop } from "./Desktop";

interface Props extends BoxProps {
    children: ReactNode[];
}

export const TestimonialList: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const total = React.Children.count(props.children);
    // prettier-ignore
    const [
        currentIndex, 
        updateIndex, 
        incrementIndex, 
        decrementIndex] = useRovingIndex(total - 1, 0, false);

    return (
        <Media query="(min-width: 640px)">
            {matches =>
                matches ? (
                    <Desktop updateIndex={updateIndex} currentIndex={currentIndex} {...props} />
                ) : (
                    <Mobile
                        updateIndex={updateIndex}
                        currentIndex={currentIndex}
                        incrementIndex={incrementIndex}
                        decrementIndex={decrementIndex}
                        {...props}
                    />
                )
            }
        </Media>
    );
};
