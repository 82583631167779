import { useCallback, useEffect, useRef } from "react";

function useScrollToRef(selector?: string, buffer = 0): [React.RefObject<any>, Function] {
    const ref = useRef<any>(null);

    const scrollTo = useCallback(() => {
        if (ref.current) {
            ref.current.scrollIntoView();
        }

        if (buffer) {
            let scrolledY = window.scrollY;

            if (scrolledY) {
                window.scroll(0, scrolledY - buffer);
            }
        }
    }, [ref]);

    useEffect(() => {
        if (selector) {
            let el = document.querySelector(selector);
            if (el) {
                ref.current = el;
            }
        }
    }, []);

    return [ref, scrollTo];
}

export default useScrollToRef;
