import React, { ReactNode } from "react";
import styled from "styled-components";
import Box, { BoxProps } from "../box/Box";
import { theme } from "styled-tools";

interface Props extends BoxProps {
    label: ReactNode;
    headline: ReactNode;
    contentColumnStart?: number;
    contentColumnSpan?: number;
}

export const Grid = styled(Box)`
    max-width: ${theme("gridMaxWidth")}px;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: ${(p) => p.theme.breakpoints[2]}) {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
    }
`;

const Headline = styled(Box)`
    grid-column: 2 / -1;
    grid-row: 1;
    @media (min-width: 1200px) {
        grid-column: 3 / -1;
    }
`;

const Label = styled(Box)`
    grid-row: 2;
    grid-column: 2 / span 2;
`;

export default ({
    headline,
    label,
    content,
    contentColumnStart = 5,
    contentColumnSpan = 7,
    ...rest
}: Props) => {
    return (
        <Grid {...rest}>
            <Label p={4}>{label}</Label>
            {headline && (
                <Headline p={4} pb={[5, 5, 5, 8]}>
                    {headline}
                </Headline>
            )}
            <Box gridRow={2} gridColumn={`${contentColumnStart} / span ${contentColumnSpan}`} p={4}>
                {content}
            </Box>
        </Grid>
    );
};
