import React from "react";
import styled, { css } from "styled-components";
import Box, {BoxProps} from "../box/Box";
import { theme } from "styled-tools";
import Media from "@onnit-js/ui/components/media";
import { Interpolation, useTheme } from "styled-components";
import { ThemeInterface } from "../themes/interfaces";
import Slider from '../Slider/Slider';

export type Variants = keyof typeof layouts;

const layouts = {
    layout1: css`
        &:nth-child(1) {
            -ms-grid-row: 1;
        }
        &:nth-child(1),
        &:nth-child(4) {
            -ms-grid-column: 1;
            -ms-grid-column-span: 8;
            grid-column: 1 / -1;
        }
        &:nth-child(2),
        &:nth-child(3) {
            -ms-grid-row: 2;
        }
        &:nth-child(2) {
            grid-column: 1 / span 4;
            -ms-grid-column: 1;
            -ms-grid-column-span: 4;
        }
        &:nth-child(3) {
            -ms-grid-column: 5;
            -ms-grid-column-span: 4;
            grid-column: 5 / span 4;
        }
        &:nth-child(4) {
            -ms-grid-row: 3;
        }
    `,
    layout2: css`
        &:nth-child(1),
        &:nth-child(2) {
            -ms-grid-column: 1;
            -ms-grid-column-span: 8;
            grid-column: 1 / -1;
        }
        &:nth-child(1) {
            -ms-grid-row: 1;
        }
        &:nth-child(2) {
            -ms-grid-row: 2;
        }
        &:nth-child(3) {
            grid-column: 1 / span 4;
            -ms-grid-column: 1;
            -ms-grid-column-span: 4;
        }
        &:nth-child(4) {
            -ms-grid-column: 5;
            -ms-grid-column-span: 4;
            grid-column: 5 / span 4;
        }
        &:nth-child(3),
        &:nth-child(4) {
            -ms-grid-row: 3;
        }
    `,
    layout3: css`
        &:nth-child(1),
        &:nth-child(2) {
            -ms-grid-row: 1;
        }
        &:nth-child(1),
        &:nth-child(4) {
            -ms-grid-column: 1;
            -ms-grid-column-span: 4;
            grid-column: 1 / span 4;
        }
        &:nth-child(2),
        &:nth-child(5) {
            -ms-grid-column: 5;
            -ms-grid-column-span: 4;
            grid-column: 5 / span 4;
        }
        &:nth-child(3) {
            -ms-grid-row: 2;
            -ms-grid-column: 1;
            -ms-grid-column-span: 8;
            grid-column: 1 / span 8;
        }
        &:nth-child(4),
        &:nth-child(5) {
            -ms-grid-row: 3;
        }
    `,
};

export const Container = styled(Box)<{ layoutCss: Interpolation<BoxProps> }>`
    @media ${theme("mediaQueries.sm")} {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: (1fr) [8];
        grid-template-columns: repeat(8, 1fr);
        grid-auto-rows: min-content;
        grid-gap: ${theme("space.5")}px;
        padding-left: ${theme("space.5")}px;
        padding-right: ${theme("space.5")}px;

        > * {
            padding: 8px;
            @supports (display: grid) {
                padding: 0;
            }
            ${(p) => p.layoutCss}
        }
    }
`;


interface Props {
    variant?: Variants;
}

const ProductImageGrid: React.FC<React.PropsWithChildren<Props>> = ({ variant = "layout1", ...rest }) => {
    const theme = useTheme();
    return (
        <Media query={(theme as ThemeInterface).mediaQueries.sm}>
            {(matches) => matches ? <Container layoutCss={layouts[variant]} {...rest} /> : <Slider {...rest} />}
        </Media>
    );
};

export default ProductImageGrid;
