import React from 'react';
import Product from "@onnit-js/ui/@types/interfaces/product/BuyBoxProduct";

export default ({ product }: { product?: Product }) => {
    if (!product) {
        return null;
    }

    return (
        <div
            data-bv-show="rating_summary"
            data-bv-product-id={product.bazaarvoiceProductId}
            style={{
                marginBottom: 24,
                minHeight: 35,
            }}
        />
    );
}
