import React from "react";
import { useTheme } from "styled-components";
import Box from "../../box/Box";
import Icon from "../../icon/Icon";
import {
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdRadioButtonUnchecked,
    MdRadioButtonChecked,
} from "react-icons/md";

interface Props {
    type: "checkbox" | "radio";
    checked: boolean;
}

interface IconProps {
    checked?: boolean;
    size?: number;
    color?: string;
}

const CheckToggleIcon = ({ checked, ...rest }: IconProps) =>
    checked ? (
        <Icon size={24} icon={MdCheckBox} {...rest} />
    ) : (
        <Icon size={24} icon={MdCheckBoxOutlineBlank} {...rest} />
    );

const RadioToggleIcon = ({ checked, ...rest }: IconProps) =>
    checked ? (
        <Icon size={24} icon={MdRadioButtonChecked} {...rest} />
    ) : (
        <Icon size={24} icon={MdRadioButtonUnchecked} {...rest} />
    );

function ToggleIcon({ checked, type = "checkbox" }: Props) {
    const theme = useTheme();
    const Comp = type === "radio" ? RadioToggleIcon : CheckToggleIcon;
    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <Comp
                checked={checked}
                size={22}
                color={
                    checked
                        ? theme.component.toggle.active.borderColor
                        : theme.component.toggle.initial.borderColor
                }
            />
        </Box>
    );
}

export default ToggleIcon;
