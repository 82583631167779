import React, { ReactNode } from "react";
import styled from "styled-components";
import Box, { BoxProps } from "../box/Box";
import { theme } from "styled-tools";

interface Props extends BoxProps {
    media: ReactNode;
    headline: ReactNode;
    label?: ReactNode;
    cta?: ReactNode;
    verticalHeadlineMobile?: boolean;
}

const Container = styled(Box)`
    max-width: ${theme("gridMaxWidth")}px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    .mediaHero01__headline {
        z-index: 2;
    }
    .mediaHero01__media {
        z-index: 1;
    }
    .mediaHero01__headline .mediaHero01__cta {
        display: none;
    }
    @media (max-width: 767px) {
        .mediaHero01__headline.mediaHero01__headline--vertical {
            position: relative;
            width: 100vw;
            > div {
                transform: rotate(-90deg);
                span {
                    font-size: 33vw;
                }
            }
        }
    }
    @media (min-width: 768px) {
        grid-template-columns: repeat(12, 1fr);
        .mediaHero01__label {
            grid-column: 2 / span 10;
            grid-row: 1;
        }
        .mediaHero01__headline {
            grid-column: 2 / span 7;
            grid-row: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .mediaHero01__media {
            grid-column: 7 / span 5;
            grid-row: 2;
        }
        .mediaHero01__headline .mediaHero01__cta {
            display: block;
        }
        .mediaHero01__cta {
            display: none;
        }
    }
`;

export default ({ label, media, headline, cta, verticalHeadlineMobile = false, ...rest }: Props) => {
    return (
        <Container display={["block", "grid", "grid", "grid"]} {...rest}>
            {label && (
                <Box p={[4]} className="mediaHero01__label">
                    {label}
                </Box>
            )}
            <Box p={[4]} className="mediaHero01__media">
                {media}
            </Box>
            <Box
                className={`mediaHero01__headline ${
                    verticalHeadlineMobile ? "mediaHero01__headline--vertical" : ""
                }`}
            >
                <Box py={[2, 2, 4]} px={4}>
                    {headline}
                </Box>
                {cta && (
                    <Box px={[4]} className="mediaHero01__cta">
                        {cta}
                    </Box>
                )}
            </Box>
            {cta && (
                <Box p={[4]} className="mediaHero01__cta">
                    {cta}
                </Box>
            )}
        </Container>
    );
};
