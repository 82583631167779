import React from "react";
import PageSection from "@onnit-js/ui/components/module/PageSection";
import { PageSection as PageSectionInterface } from "../../interfaces/SiteContent";
import {BoxProps} from "@onnit-js/ui/components/box/Box";
import ContentfulComponentMapper from "./ContentfulComponentMapper";

interface Props extends BoxProps {
    data: PageSectionInterface;
}

const ContentfulPageSection = ({ data: { content, boxProps, ...rest } }: Props) => {
    return (
        <PageSection {...rest} {...boxProps}>
            <ContentfulComponentMapper content={content} />
        </PageSection>
    );
};

export default ContentfulPageSection;
