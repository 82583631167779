import {Favorite} from "../../../../@types/interfaces/favorites/Favorite";
import {PaginatedResponse} from "../../../../@types/interfaces/PaginatedResponse";
import config from "../../../../config";

const API_URL = config.API_URL;

const defaultOptions: RequestInit = {
    headers: new Headers({ "content-type": "application/json" }),
    credentials: "include",
};

export function get(customerId: number): Promise<PaginatedResponse<Favorite>> {
    return fetch(`${API_URL}/customers/${customerId}/favorites?per_page=2000`, defaultOptions).then(
        handleInitialResponse
    );
}

export function create(customerId: number, productId: number): Promise<Favorite> {
    return fetch(`${API_URL}/customers/${customerId}/favorites`, {
        ...defaultOptions,
        method: "POST",
        body: JSON.stringify({ product_id: productId }),
    }).then(handleResponse);
}

export function destroy(customerId: number, favoriteId: number): Promise<undefined> {
    return fetch(`${API_URL}/customers/${customerId}/favorites/${favoriteId}`, {
        ...defaultOptions,
        method: "DELETE",
    }).then(handleResponse);
}

function handleResponse(response: Response) {
    if (!response.ok) {
        if ([401, 403].includes(response.status)) {
            window.location.href = `/cart/logoff.php?next=${encodeURIComponent(window.location.pathname)}`;
        }

        return Promise.reject({
            status: response.status,
            statusText: response.statusText,
        });
    }

    return response.headers.get("content-type") === "application/json" ? response.json() : response.text();
}

function handleInitialResponse(response: Response) {
    if (!response.ok) {
        if ([403].includes(response.status)) {
            window.location.href = `/cart/logoff.php?next=${encodeURIComponent(window.location.pathname)}`;
        }

        return Promise.reject({
            status: response.status,
            statusText: response.statusText,
        });
    }

    return response.headers.get("content-type") === "application/json" ? response.json() : response.text();
}
