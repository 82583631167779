import React, { ReactNode } from "react";
import Box, {BoxProps} from "@onnit-js/ui/components/box/Box";
import {Picture} from "@onnit-js/ui/components/image";

interface Props extends BoxProps {
    imgSrc: string[];
    reverse?: boolean;
    children?: ReactNode;
}

export default function OfferHero({ imgSrc, reverse, children, ...rest }: Props) {
    return (
        <Box {...rest} display={["block", "block", "grid"]} gridTemplateColumns="repeat(12, 1fr)">
            <Box zIndex={1} gridRow="1" gridColumn="1 / -1">
                <Picture
                    lazyLoad={false}
                    width={1456}
                    height={754}
                    src={imgSrc}
                    alt="Onnit Offer Hero Background Image" />
            </Box>
            <Box
                zIndex={2}
                gridRow="1"
                style={{ gridColumnStart: reverse ? 6 : 2, gridColumnEnd: "span 6" }}
                display={["block", "block", "flex"]}
                flexDirection="column"
                justifyContent="center"
                maxWidth={["100%", "100%", "648px"]}>
                <Box bg={["grays.8", "grays.8", "rgba(0,0,0,0.5)"]} px={5} py={7}>
                    {children}
                </Box>
            </Box>
        </Box>
    );
}
