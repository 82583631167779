import React from 'react';
import Box, { BoxProps } from '../box/Box';
import Icon from '../icon/Icon';
import Text from '../text';
import { IoIosCheckmark } from 'react-icons/io';
import { IconType } from 'react-icons/lib';

interface BaseProps extends BoxProps {
    label: string;
    icon: IconType;
}

export function Tag({ label, icon, color = 'grays.6', bg = 'grays.0', ...rest }: BaseProps) {
    return (
        <Box {...rest} display="inline-flex" bg={bg} alignItems="center" borderRadius="12px">
            <Text fontSize={0} color={color} pl={2}>
                {label}
            </Text>
            <Icon icon={icon} color={color} size={24} />
        </Box>
    );
}

export default Tag;

type CheckMarkTagProps = Omit<BaseProps, "icon">;
export const CheckMarkTag = (props: CheckMarkTagProps) => <Tag {...props} icon={IoIosCheckmark}/>;
