import React, { useEffect, useState } from "react";
import Button from "../../button/Button";
import Product from "../../../@types/interfaces/product/BuyBoxProduct";
import BuyBoxFooter from "../shared/BuyBoxFooter";
import {useAddToBag} from "../../../hooks/useAddToBag";
import {CartProductConfig} from "../../../@types/interfaces/CartProductConfig";

interface ViewProps {
    product: Product;
    freeShippingThreshold: number;
    onSuccess?: () => void;
    onError?: (error: any) => void;
    showFooter?: boolean;
    addToBagMiddleware?: (config: CartProductConfig) => Promise<CartProductConfig>;
}

const ApparelInStockView: React.FC<React.PropsWithChildren<ViewProps>> = ({
    product,
    freeShippingThreshold,
    onSuccess,
    onError,
    addToBagMiddleware,
    showFooter = true,
    children,
}) => {
    const { isAddingToBag, addToBag } = useAddToBag({ onSuccess, onError });
    const [adjustedPrice, setAdjustedPrice] = useState(0);

    // Update Onnit X Rewards points when product configuration changes.
    useEffect(() => {
        const adjustedPrice = parseFloat(product.price_raw);
        setAdjustedPrice(adjustedPrice);
    }, [product]);

    return (
        <>
            <Button
                mt={5}
                width={1}
                display="block"
                disabled={isAddingToBag}
                onClick={() => {
                    const config = {
                        product_id: product.id,
                        quantity: 1,
                        subscription_interval: null,
                    };

                    if (addToBagMiddleware) {
                        addToBagMiddleware(config)
                            .then((newConfig) => {
                                addToBag(newConfig);
                            })
                            .catch(console.error);
                    } else {
                        addToBag(config);
                    }
                }}
            >
                {isAddingToBag ? "Adding..." : `Add to Bag`}
            </Button>

            {children}

            {showFooter && (
                <BuyBoxFooter adjustedPrice={adjustedPrice} freeShippingThreshold={freeShippingThreshold} />
            )}
        </>
    );
};
export default ApparelInStockView;
