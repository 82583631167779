import React, { ReactNode } from "react";
import Box, { BoxProps } from "../box/Box";

interface Props extends BoxProps {
    item1: ReactNode;
    item2: ReactNode;
    item3: ReactNode;
}

export default ({ item1, item2, item3, ...rest }: Props) => {
    return (
        <Box
            {...rest}
            maxWidth="${theme('gridMaxWidth')}px"
            mx="auto"
            display="grid"
            gridTemplateColumns={["1fr 1fr", "repeat(12, 1fr)"]}
            gridTemplateRows={[
                "min-content min-content",
                "min-content 1fr 1fr 1fr 1fr 1fr 1fr"
            ]}
            px={[1, 3]}
        >
            <Box
                p={2}
                display={["flex", "block"]}
                alignItems="flex-end"
                gridColumn={["2 / 3", "5 / span 2"]}
                gridRow={["1 / 1", "1 / span 1"]}
            >
                {item1}
            </Box>
            <Box
                p={2}
                display={["flex", "block"]}
                alignItems="flex-end"
                gridColumn={["1 / 2", "2 / span 2"]}
                gridRow={["1 / 1", "3 / span 2"]}
            >
                {item2}
            </Box>
            <Box
                p={2}
                gridColumn={["1 / -1", "7 / span 5"]}
                gridRow={["2 / 3", "4 / span 4"]}
            >
                {item3}
            </Box>
        </Box>
    );
};
