import React from "react";
import Text from "../../text/Text";
import Box from "../../box/Box";

const DiscontinuedView = () => {
    return (
        <Box>
            <Text fontWeight="bold" m={3} color="gameRed">
                This product has been discontinued.
            </Text>
        </Box>
    );
};

export default DiscontinuedView;
