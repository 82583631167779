import React, { ReactNode } from "react";
import Media from "@onnit-js/ui/components/media";
import ThemeProvider from "@onnit-js/ui/components/themes/ThemeProvider";
import GlobalStyle from "@onnit-js/ui/components/themes/GlobalStyle";
import theme from "@onnit-js/ui/components/themes/light";
import Box from "@onnit-js/ui/components/box/Box";
import Button from "@onnit-js/ui/components/button/Button";
import Text, {List, ListItem, MultiColumnText, Sup} from "@onnit-js/ui/components/text";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import usePageData from "../../hooks/usePageData";
import useScrollToRef from "../../hooks/useScrollToRef";
import {BgImage, Img} from "@onnit-js/ui/components/image";
import SupplementFacts from "../../components/common/SupplementFacts";
import Grid from "@onnit-js/ui/components/module/grid/12/Grid";
import MediaHero01 from "@onnit-js/ui/components/module/MediaHero01";
import MediaPano from "@onnit-js/ui/components/module/MediaPano";
import CopyDisplay02 from "@onnit-js/ui/components/module/CopyDisplay02";
import MediaCollage01 from "@onnit-js/ui/components/module/MediaCollage01";
import ImageContentPortrait from "@onnit-js/ui/components/module/ImageContentPortrait";
import MediaCollage02 from "@onnit-js/ui/components/module/MediaCollage02";
import Embed from "@onnit-js/ui/components/video/Embed";
import DaggerSymbol from "@onnit-js/ui/components/text/DaggerSymbol";
import Dash from "@onnit-js/ui/components/icon/Dash";
import UsageAndDisclaimers from "../../components/common/UsageAndDisclaimers";
import OfferHero from "../../components/common/offer/OfferHero";
import AsSeenIn from "../../components/common/offer/AsSeenIn";
import FiveStarTestimonials from "../../components/common/FiveStarTestimonials";
import testimonials from "./testimonials";
import Faqs from "../../components/common/Faqs";
import faqs from "./faq";
import OnnitLogoBackground from "../../components/common/OnnitLogoBackground";

import StickyHeader from "../../components/common/StickyHeader";
import config from "../../config/config";

interface Props {
    banner?: ReactNode;
    offers: (imgURL: string, mainColor: string) => ReactNode;
}

function Page({ banner, offers }: Props) {
    const onnitContext = useOnnitContext();
    const { pageData } = usePageData();
    const [buyBoxRef, scrollToBuyBox] = useScrollToRef();

    if (!pageData || !onnitContext) return null;

    const IMG_PARAMS = config.IMG_PARAMS;
    const IMG_URL = onnitContext.url.cdn.static_images + "/product-page/total-gut-health/new";
    const OFFER_IMG_URL = onnitContext.url.cdn.static_images + "/product-page/total-gut-health/new/offer";
    const tghGreen = "#20A090";
    const darkTeal = "#54C2B7";

    return (
        <ThemeProvider theme={theme}>
            <>
                <GlobalStyle />
                {banner}
                <StickyHeader showHelp={false}>
                    <Button size="small" raised={false} onClick={() => scrollToBuyBox()}>
                        Try Now
                    </Button>
                </StickyHeader>
                <div className="hide-ie" style={{ marginTop: -56 }}>
                    <OfferHero
                        reverse
                        imgSrc={[
                            `${OFFER_IMG_URL}/mobile_header_image.jpg${IMG_PARAMS}`,
                            `${OFFER_IMG_URL}/desktop_header_image.jpg${IMG_PARAMS}`,
                        ]}
                    >
                        <Text typeStyle="copyDisplay02" fontSize={[5, 5, 6, 6, 6, 7, 7]} color="sunwashGold" mb={4}>
                            Total GUT HEALTH
                            <Sup variant="trade" /> IS EVERYTHING YOU NEED TO OPTIMIZE YOUR GUT!
                        </Text>
                        <Text color="white" mb={6} fontSize={[2, 2, 4]}>
                            Includes digestive enzymes, prebiotics, probiotics, saccharomyces boulardii and betaine HCI.
                            <Sup />
                        </Text>
                        <Button
                            size="medium"
                            width={[1, 1, "auto"]}
                            onClick={() => scrollToBuyBox()}
                            textColor="black"
                            color="white"
                            raised
                        >
                            TRY IT NOW - RISK FREE
                        </Button>
                    </OfferHero>

                    <AsSeenIn />

                    <Box bg="#232523" py={[6, 6, 8]} ref={buyBoxRef}>
                        <Box py={[2, 2, 6, 8]} px={[5, 5, 5, 10]}>
                            <Box gridColumn="1 / -1">
                                <Text as="h2" typeStyle="headlineHero02" color={tghGreen} mb={4}>
                                    WANT TO OPTIMIZE YOUR GUT?
                                </Text>
                                <Text as="h2" typeStyle="copy01" color="white" maxWidth="700px">
                                    CHOOSE YOUR PACKAGE AND SAVE BIG ON YOUR ORDER OF Total GUT HEALTH
                                    <Sup variant="trade" />.
                                </Text>
                            </Box>
                        </Box>
                        {offers(OFFER_IMG_URL, darkTeal)}
                    </Box>

                    <MediaHero01
                        bg="grays.8"
                        pt={[8, 8, 8, 10]}
                        pb={[8, 8, 8, 10, "16%"]}
                        media={
                            <Img
                                src={`${IMG_URL}/mediaHero.jpg?q=40`}
                                alt="Woman opening pack of Total GUT HEALTH™ Before a meal"
                                width={1200}
                                height={1200}
                            />
                        }
                        headline={
                            <>
                                <Text typeStyle="headlineHero01" color={tghGreen}>
                                    Total
                                </Text>
                                <Text typeStyle="headlineHero01" color={tghGreen}>
                                    Gut
                                </Text>
                                <Text typeStyle="headlineHero01" color={tghGreen}>
                                    Health
                                    <Sup variant="trade" />
                                </Text>
                            </>
                        }
                    />

                    <Media
                        query="(min-width: 960px)"
                        render={() => (
                            <MediaPano p={0} mt={[0, 0, "-8%"]} mb={[0, 0, 0, 8]}>
                                <Img
                                    src={`${IMG_URL}/pano.jpg?q=90`}
                                    alt="Delicious tacos and a box of Total GUT HEALTH™"
                                    width={1666}
                                    height={462}
                                />
                            </MediaPano>
                        )}
                    />

                    <ImageContentPortrait
                        py={[6, 6, 6, 8]}
                        image={
                            <Img
                                src={`${IMG_URL}/helps1.jpg?q=90`}
                                alt="Man opening packet of Total GUT HEALTH™"
                                width={934}
                                height={1212}
                            />
                        }
                        content={
                            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                                <Text color="grays.6" typeStyle="copyDisplay02" mb={6}>
                                    How Total GUT
                                    <br /> HEALTH
                                    <Sup variant="trade" /> Helps <br />
                                    Optimize You
                                </Text>
                                <Text as="p" typeStyle="copyHero02" color="grays.6">
                                    Research suggests that the gut microbiome—the balance of bacteria in your
                                    intestines—is the foundation for a strong immune system and optimal brain function,
                                    as well as aids in proper digestion of all the nutrients you need to be healthy,
                                    transform your body, and support performance.
                                    <span className="oui-sup">
                                        <DaggerSymbol />
                                    </span>
                                </Text>
                            </Box>
                        }
                    />

                    <ImageContentPortrait
                        pb={[6, 8, 8, 8, 8, 10, 12]}
                        reverse={true}
                        image={
                            <Img src={`${IMG_URL}/helps2.jpg?q=90`} alt="Woman eating Taco" width={928} height={1200} />
                        }
                        content={
                            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                                <Text as="p" typeStyle="copyHero02" color="grays.6">
                                    Total GUT HEALTH
                                    <Sup variant="trade" /> packets offer digestive enzymes, probiotics, prebiotics, and
                                    other nutrients that help support a healthy digestive system and gut microbiome,
                                    allowing you to get more out of the good food you eat— and pay a smaller price for
                                    the bad stuff.
                                    <span className="oui-sup">
                                        <DaggerSymbol />
                                    </span>
                                </Text>
                            </Box>
                        }
                    />

                    <BgImage py={[6, 6, 6, 8]} src={["", `${IMG_URL}/powderBg.jpg?q=50`]}>
                        <Grid>
                            <Box gridColumn={["1 / -1", "1 / -1", "1 / -1", "3 / span 8"]} px={4}>
                                <Embed
                                    title="Total GUT HEALTH™ Product Video"
                                    src="https://player.vimeo.com/video/363676060"
                                />

                                <Box py={[6, 6, 6, 8]}>
                                    <Dash mb={5} />
                                    <List style={{ paddingLeft: 32 }}>
                                        <ListItem typeStyle="copyHero01" color="black">
                                            Helps break down food &amp; absorb nutrients
                                            <span className="oui-sup">
                                                <DaggerSymbol />
                                            </span>
                                        </ListItem>
                                        <ListItem typeStyle="copyHero01" color="black">
                                            Aids in immune system support
                                            <span className="oui-sup">
                                                <DaggerSymbol />
                                            </span>
                                        </ListItem>
                                        <ListItem typeStyle="copyHero01" color="black">
                                            Helps promote healthy gut flora
                                            <span className="oui-sup">
                                                <DaggerSymbol />
                                            </span>
                                        </ListItem>
                                        <ListItem typeStyle="copyHero01" color="black">
                                            Assists with the digestion of major macronutrients
                                            <span className="oui-sup">
                                                <DaggerSymbol />
                                            </span>
                                        </ListItem>
                                    </List>
                                </Box>
                            </Box>
                        </Grid>
                    </BgImage>

                    <MediaCollage02
                        py={[6, 6, 6, 8]}
                        item1={
                            <Img
                                src={`${IMG_URL}/collage1.jpg?q=80`}
                                alt="Man and woman walking to river"
                                width={448}
                                height={336}
                            />
                        }
                        item2={
                            <Img
                                src={`${IMG_URL}/collage2.jpg?q=80`}
                                alt="Man and woman enjoyed a sunny day"
                                width={344}
                                height={344}
                            />
                        }
                        item3={
                            <Img
                                src={`${IMG_URL}/collage3.jpg?q=80`}
                                alt="Man and woman discussing life"
                                width={1168}
                                height={876}
                            />
                        }
                    />

                    <ImageContentPortrait
                        py={[6, 6, 6, 8]}
                        image={<Img src={`${IMG_URL}/dan.jpg?q=100`} alt="Dr. Dan Engle" width={464} height={600} />}
                        content={
                            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                                <Text as="p" typeStyle="copyHero02" color="grays.6" mb={5}>
                                    “Gut health is at the forefront of holistic medicine right now. We have long-known
                                    the importance of good digestion, but recent research is shedding light on the
                                    connection of the gut and optimal brain and immune function. I know of no other
                                    product that combines all of these gut optimizing ingredients in a single pack. This
                                    is one of the most important innovations that I have seen in the market.”{" "}
                                    <span className="oui-sup">
                                        <DaggerSymbol />
                                    </span>
                                </Text>
                                <Text typeStyle="copy05" color="black">
                                    –Dr. Dan Engle
                                </Text>
                            </Box>
                        }
                    />

                    <Box bg="grays.8">
                        <CopyDisplay02 py={[6, 8, 8, 10]} dashColor={tghGreen}>
                            <Text color={tghGreen} typeStyle="copyDisplay02" mb={[6, 6, 8]}>
                                One Packet. 10 Seconds.
                                <br />
                                Optimized Digestion.
                            </Text>
                            <MultiColumnText>
                                <Text as="p" typeStyle="copyHero02" color="grays.2" mb={4}>
                                    You can’t eat perfectly all the time. Whether you’re enjoying the occasional binge
                                    on pizza, fried foods, and other indulgences, or traveling abroad where food safety
                                    is uncertain, you shouldn’t be punished by feeling gassy, bloated, and tired for a
                                    day afterward. Supplementing with the right ingredients can help let the air out of
                                    your belly and keep you regular, so you can eat every meal worry-free.
                                    <span className="oui-sup">
                                        <DaggerSymbol />
                                    </span>
                                    &nbsp;It takes seconds to tear open a Total GUT HEALTH
                                    <Sup variant="trade" /> packet, and consume the contents, and moments for the
                                    ingredients to start working.
                                </Text>
                            </MultiColumnText>
                        </CopyDisplay02>
                    </Box>

                    <MediaCollage01
                        py={[6, 6, 8, 10]}
                        item1={<Img src={`${IMG_URL}/collage_2_1.jpg?q=80`} alt="Radishes" width={448} height={692} />}
                        item2={
                            <Img
                                src={`${IMG_URL}/collage_2_2.jpg?q=80`}
                                alt="Ginger Root and Turmeric"
                                width={448}
                                height={692}
                            />
                        }
                        item3={
                            <Img src={`${IMG_URL}/collage_2_3.jpg?q=80`} alt="Ginger Root" width={938} height={1294} />
                        }
                    />

                    <CopyDisplay02 py={[6, 6, 6, 8]} dashColor="grays.4">
                        <Text color="grays.4" typeStyle="copyDisplay02" mb={[6, 6, 6, 8]}>
                            What's in the Total GUT <Box display={["none", "block"]} />
                            HEALTH
                            <Sup variant="trade" /> packet?
                        </Text>
                        <Box display={["block", "block", "flex"]}>
                            <Box flex="1 0 44%" mr={[0, 5, 6, 8]}>
                                <Text typeStyle="copy01" color={tghGreen} mb={2}>
                                    DIGESTech®
                                </Text>
                                <Text as="p" typeStyle="copyHero02" color="grays.4" mb={6}>
                                    Our flagship full-spectrum enzyme supplement that helps you digest fats, lactose,
                                    carbs, protein, and fiber.{" "}
                                    <span className="oui-sup">
                                        <DaggerSymbol />
                                    </span>
                                </Text>

                                <Text typeStyle="copy01" color={tghGreen} mb={2}>
                                    5 Strains of Probiotics
                                </Text>
                                <Text as="p" typeStyle="copyHero02" color="grays.4" mb={6}>
                                    Good bacteria are only beneficial if they reach your intestines, and stomach acids
                                    usually kill them off before they get that far. That’s why we included five of the
                                    most important naturally acid-resistant strains: L. acidophilus DDS-1, L. plantarum,
                                    L. rhamnosus, B. infantis, and B. lactis.
                                </Text>
                                <Text typeStyle="copy01" color={tghGreen} mb={2}>
                                    2 Types of Organic Prebiotics
                                </Text>
                                <Text as="p" typeStyle="copyHero02" color="grays.4" mb={6}>
                                    Organic Jerusalem artichoke and organic dandelion root help feed the probiotic
                                    strains, aiding a positive balance in gut flora.
                                    <span className="oui-sup">
                                        <DaggerSymbol />
                                    </span>
                                </Text>
                            </Box>
                            <Box flex="1 0 44%">
                                <Text typeStyle="copy01" color={tghGreen} mb={2}>
                                    Betaine HCI
                                </Text>
                                <Text as="p" typeStyle="copyHero02" color="grays.4" mb={6}>
                                    A natural chemical that helps promote stomach acid production to break down food.{" "}
                                    <span className="oui-sup">
                                        <DaggerSymbol />
                                    </span>
                                </Text>
                                <Text typeStyle="copy01" color={tghGreen} mb={2}>
                                    Saccharomyces Boulardii
                                </Text>
                                <Text as="p" typeStyle="copyHero02" color="grays.4" mb={6}>
                                    A gut-friendly yeast that acts as a probiotic, S. Boulardii survives stomach acid to
                                    help restock your gut with beneficial microorganisms that aid with normal
                                    elimination.{" "}
                                    <span className="oui-sup">
                                        <DaggerSymbol />
                                    </span>
                                </Text>
                                <Text as="p" typeStyle="copyHero02" color="grays.4" mb={6}>
                                    Learn more about{" "}
                                    <a
                                        style={{ textDecoration: "underline" }}
                                        href="https://www.onnit.com/academy/benefits-of-digestive-supplements/"
                                        title="Benefits of Digestive Enzyme Supplements"
                                        target="_blank"
                                    >
                                        digestive enzymes
                                    </a>
                                    , and{" "}
                                    <a
                                        style={{ textDecoration: "underline" }}
                                        href="https://www.onnit.com/academy/saccharomyces-boulardii/"
                                        title="What to Know about Saccharomyces Boulardii: Benefits & Uses"
                                        target="_blank"
                                    >
                                        S. Boulardii
                                    </a>
                                    .
                                </Text>
                            </Box>
                        </Box>
                    </CopyDisplay02>

                    <OnnitLogoBackground
                        fillColor="#54c2b7"
                        fillOpacity={0.1}
                        bgFillColor="black"
                        backgroundColor="#000000"
                        backgroundSize="cover"
                    >
                        <FiveStarTestimonials
                            titleColor={darkTeal}
                            title={
                                <>
                                    What customers are saying about Total GUT HEALTH
                                    <Sup variant="trade" />
                                </>
                            }
                            testimonials={testimonials}
                            textColor="white"
                        />
                        <Box display="flex" justifyContent="center" py={6}>
                            <Button
                                size="medium"
                                width={[1, 1, "auto"]}
                                onClick={() => scrollToBuyBox()}
                                textColor="black"
                                color="white"
                                raised
                            >
                                TRY IT NOW - RISK FREE
                            </Button>
                        </Box>
                    </OnnitLogoBackground>
                </div>

                <UsageAndDisclaimers supplementFacts={<SupplementFacts />}>
                    <Text
                        fontSize={0}
                        color="grays.4"
                        target="_blank"
                        href="/terms-and-conditions/subscription/"
                        as="a"
                    >
                        Read the Terms and Conditions here.
                    </Text>
                </UsageAndDisclaimers>

                <Faqs faqs={faqs} px={4} pb={[6, 6, 8]} />
            </>
        </ThemeProvider>
    );
}

export default Page;
