import React, { ReactNode } from "react";

const SuperScriptSpecialChars: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ text: string }>>> = ({ text }) => {
    const regex = new RegExp(/(™|®|†)/gm);
    return (
        <>
            {text.split("").reduce<ReactNode[]>((acc, char: string, index) => {
                if (regex.test(char)) {
                    acc = [
                        ...acc,
                        <sup key={`sup-${index}`} className="oui-sup">
                            {char}
                        </sup>,
                    ];
                } else {
                    // // normal character
                    if (index === 0) {
                        acc.push(char);
                    } else {
                        const lastChar = acc[acc.length - 1];
                        if (lastChar && typeof lastChar === "string") {
                            acc[acc.length - 1] = lastChar.concat(char);
                        } else {
                            acc.push(char);
                        }
                    }
                }

                return acc;
            }, [])}
        </>
    );
};

export default SuperScriptSpecialChars;
