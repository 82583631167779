import { zonedTimeToUtc } from "date-fns-tz";
import isWithinInterval from "date-fns/isWithinInterval";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";

const TIME_ZONE = "America/Chicago";

export function getUrlParameter(name: string) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export function isValidEmail(email: string) {
    if (email.trim() === "") return false;
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.trim());
}

export function isValidPhoneNumber(n: string | null | undefined) {
    if (!n || n.trim() === "") return false;
    return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g.test(n.trim());
}

export function todayInDateRange(startDateTime: string, endDateTime: string, now: string | null = null): boolean {
    const serverTime = window.onnit_context?.server_time;

    let nowTz = now ? zonedTimeToUtc(now, TIME_ZONE) : serverTime ? new Date(serverTime) : new Date();
    const start = zonedTimeToUtc(startDateTime, TIME_ZONE);
    const end = zonedTimeToUtc(endDateTime, TIME_ZONE);
    return isWithinInterval(nowTz, {
        start,
        end,
    });
}

export function afterDate(afterDateTime: string, now: string | null = null): boolean {
    const serverTime = window.onnit_context?.server_time;

    let nowTz = now ? zonedTimeToUtc(now, TIME_ZONE) : serverTime ? new Date(serverTime) : new Date();
    const after = zonedTimeToUtc(afterDateTime, TIME_ZONE);
    return isAfter(nowTz, after);
}

export function beforeDate(beforeDateTime: string, now: string | null = null): boolean {
    const serverTime = window.onnit_context?.server_time;

    let nowTz = now ? zonedTimeToUtc(now, TIME_ZONE) : serverTime ? new Date(serverTime) : new Date();
    const before = zonedTimeToUtc(beforeDateTime, TIME_ZONE);
    return isBefore(nowTz, before);
}

/*
 Usage: MoneyFormat.format(number)
*/
export const MoneyFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export function stripTags(str: string): string {
    str = str || "";
    let _text = str
        .replace(/<!--[\s\S]*?(-->|$)/g, "")
        .replace(/<(script|style)[^>]*>[\s\S]*?(<\/\1>|$)/gi, "")
        .replace(/<\/?[a-z][\s\S]*?(>|$)/gi, "");

    // If the initial text is not equal to the modified text,
    // do the search-replace again, until there is nothing to be replaced.
    if (_text !== str) {
        return stripTags(_text);
    }

    // Return the text with stripped tags.
    return _text;
}

/**
 * This should be used for more accurate rounding instead of `toFixed`.
 * @see https://www.jacklmoore.com/notes/rounding-in-javascript/
 */
export const round = (value: number, decimals: number): number => (
    Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals)
);
