import React, { ReactElement } from "react";
import ContentfulHero from "../../components/contentful/banners/ContentfulHero";
import ContentfulFeature from "../../components/contentful/banners/ContentfulFeature";
import ContentfulCards from "../../components/contentful/ContentfulCards";
import {
    ContentTypeId,
    ContentCardCollection,
    Banner,
    PageHeader,
    ValidPageContentTypes,
    PageSection,
    MediaList,
    FlashSaleBanner,
    ImageContainer,
    Grid,
    TextList,
    TextContainer,
    ContentNode,
} from "../../interfaces/SiteContent";
import ContentfulPageHeader from "./ContentfulPageHeader";
import ContentfulPageSection from "./ContentfulPageSection";
import ContentfulMediaList from "./ContentfulMediaList";
import ContentfulFlashBanner from "./ContentfulFlashBanner";
import ContentfulImageContainer from "./ContentfulImageContainer";
import ContentfulGrid from "./ContentfulGrid";
import ContentfulTextList from "./ContentfulTextList";
import ContentfulTextContainer from "./ContentfulTextContainer";
import ContentfulSignUpArea from "./ContentfulSignUpArea";
import { SignUpAreaProps } from "../common/SignUpArea";
import RecommendationsPage from "./RecommendationsPage";

interface Props {
    content: ContentNode<ContentTypeId, ValidPageContentTypes>[];
}

const ContentfulComponentMapper: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ content }) => {
    const CONTENTFUL_PARAMS = "?q=40&fm=jpg&fl=progressive";
    let position = 1;

    const mapComponent = function (typeId: string, data: ValidPageContentTypes, key: string): ReactElement {
        switch (typeId) {
            case ContentTypeId.HERO:
                return (
                    <ContentfulHero
                        key={key}
                        data={data as Banner}
                        imgParams={CONTENTFUL_PARAMS}
                        position={position++}
                    />
                );
            case ContentTypeId.FEATURE:
                return (
                    <ContentfulFeature
                        key={key}
                        data={data as Banner}
                        imgParams={CONTENTFUL_PARAMS}
                        position={position++}
                    />
                );
            case ContentTypeId.CARDS:
                return <ContentfulCards key={key} data={data as ContentCardCollection} px={4} my={[6, 6, 6, 8]} />;
            case ContentTypeId.PAGE_HEADER:
                return <ContentfulPageHeader key={key} data={data as PageHeader} />;
            case ContentTypeId.PAGE_SECTION:
                return <ContentfulPageSection key={key} data={data as PageSection} />;
            case ContentTypeId.MEDIA_LIST:
                return <ContentfulMediaList key={key} data={data as MediaList} />;
            case ContentTypeId.FLASH_SALE_BANNER:
                return <ContentfulFlashBanner key={key} data={data as FlashSaleBanner} />;
            case ContentTypeId.IMAGE_CONTAINER:
                return <ContentfulImageContainer key={key} data={data as ImageContainer} />;
            case ContentTypeId.GRID:
                return <ContentfulGrid key={key} data={data as Grid} />;
            case ContentTypeId.TEXT_LIST:
                return <ContentfulTextList key={key} data={data as TextList} />;
            case ContentTypeId.TEXT_CONTAINER:
                return <ContentfulTextContainer key={key} data={data as TextContainer} />;
            case ContentTypeId.SIGN_UP_AREA:
                return <ContentfulSignUpArea key={key} data={data as SignUpAreaProps} />;
            case ContentTypeId.AMAZON_PERSONALIZE_HOME_PAGE:
                return <RecommendationsPage key={key} />;
        }
        return <></>;
    };

    return (
        <>
            {content.reduce(
                (acc: ReactElement[], entry: ContentNode<ContentTypeId, ValidPageContentTypes>, i: number) => {
                    acc.push(mapComponent(entry.typeId, entry.data, `${entry.typeId}-${i}`));
                    return acc;
                },
                [] as ReactElement[]
            )}
        </>
    );
};

export default ContentfulComponentMapper;
