import React, {PropsWithChildren} from 'react';
import { useInView} from 'react-intersection-observer';
import {Box, BoxProps} from '../box/Box';
import {lazyOptions} from './shared';

interface Props extends BoxProps {
    src: string | string[];
    lazy?: boolean;
}

export const BgImage:React.FC<PropsWithChildren<Props>> = ({
  src,
  lazy = true,
  backgroundSize = 'cover',
  backgroundPosition = 'center',
  backgroundRepeat = 'no-repeat',
  ...rest
}: Props) => {
    const [ref, inView] = useInView(lazyOptions);

    let srcUrls: string | string[] | undefined = undefined;

    if (inView) {
        srcUrls = Array.isArray(src) ? src.map(url => `url("${url}")`) : `url("${src}")`;
    }

    return <Box ref={ref}
                backgroundSize={backgroundSize}
                backgroundPosition={backgroundPosition}
                backgroundRepeat={backgroundRepeat}
                {...rest} backgroundImage={srcUrls}/>;
};

export default BgImage;
