import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import Box, { BoxProps } from "../box/Box";
import { placeholderSrc } from "../image/shared";

interface Props extends BoxProps {
    src: string;
    width?: string | number;
    height?: string | number;
    title: string;
    lazyLoad?: boolean;
}

const lazyOptions = {
    triggerOnce: true,
    rootMargin: "300px 0px",
};

function Embed({ src, width = "640", height = "360", title, lazyLoad = true, ...rest }: Props) {
    const [ref, inView] = useInView(lazyOptions);
    const [isLoaded, setLoaded] = useState(false);
    return (
        <Box display="flex">
            <Box
                {...rest}
                ref={ref}
                style={{
                    flex: "1 1 0%",
                    position: "relative",
                    paddingBottom: `calc(${height} / ${width} * 100%)`,
                    overflow: "hidden",
                    background:
                        "#000 url(https://onnits3.imgix.net/interface/loading-light.gif) no-repeat 50% 50%",
                    height: "100%",
                }}
            >
                {!lazyLoad || inView ? (
                    <iframe
                        title={title}
                        src={src}
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            transition: "opacity 300ms",
                            opacity: isLoaded ? 1 : 0,
                        }}
                        onLoad={() => setLoaded(true)}
                        width={width}
                        height={height}
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen
                    />
                ) : (
                    <img
                        alt={title}
                        src={placeholderSrc([width, height])}
                        width={width}
                        height={height}
                        style={{ display: "block", aspectRatio: "16 / 9", width: "100%", height: "auto" }}
                    />
                )}
            </Box>
        </Box>
    );
}

export default Embed;
