import React from "react";
import styled from "styled-components";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import Text from "@onnit-js/ui/components/text";
import Box, {BoxProps} from "@onnit-js/ui/components/box/Box";
import {Img} from "@onnit-js/ui/components/image";
import Product from "@onnit-js/ui/@types/interfaces/product/BuyBoxProduct";

interface Props extends BoxProps {
    products: Product[];
}

function truncateName(name: string): string {
    const start = name.indexOf("(");
    return name.substr(0, start - 1);
}

const Container = styled.a`
    margin: ${p => p.theme.space[2]}px;
    flex: 1;
    text-align: center;
`;

export default ({ products, ...rest }: Props) => {
    const onnitContext = useOnnitContext();

    if (!onnitContext) {
        return null;
    }

    return (
        <Box {...rest} bg="grays.0" py={5} px={3}>
            <Text color="grays.6" textAlign="center" fontSize={1} mb={1} fontWeight="bold">
                You may also like
            </Text>
            <Box display="flex">
                {products.map(product => (
                    <Container
                        key={product.meta.slug}
                        href={`/${product.meta.slug}`}
                        title={product.name}>
                        <Img
                            style={{ maxWidth: 100, display: "inline-block" }}
                            alt={product.name}
                            src={`${onnitContext.url.cdn.static_images}/authority/${product.meta["authority-id"]}.png?w=176`}
                            width={176}
                            height={236}
                        />
                        <Text color="grays.6" fontSize={0} fontWeight="bold" mt={2}>
                            {truncateName(product.name)}
                        </Text>
                    </Container>
                ))}
            </Box>
        </Box>
    );
};
