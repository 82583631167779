import React from "react";
import SignUpArea, { SignUpAreaProps } from "../common/SignUpArea";

interface Props {
    data: SignUpAreaProps;
}

const ContentfulSignUpArea = ({ data, data: { boxProps = [] } }: Props) => {
    return <SignUpArea {...data} {...boxProps} />;
};


export default ContentfulSignUpArea;
