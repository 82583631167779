import { useCallback, useEffect, useState } from "react";
import OnnitPageData from "../interfaces/OnnitPageData";
import Product from "@onnit-js/ui/@types/interfaces/product/BuyBoxProduct";

type FindProduct = (pid: number) => Product | null;

export default function usePageData() {
    const [pageData, setPageData] = useState<OnnitPageData | null>(null);

    const findProduct: FindProduct = useCallback(
        (pid: number) => {
            // Merge products and other_products for convenience
            if (!pageData) {
                return null;
            }
            const a = pageData.products ?? [];
            const b = pageData.other_products ?? [];
            return a.concat(b).find((product) => product.id === pid) ?? null;
        },
        [pageData]
    );

    useEffect(() => {
        if (window) {
            if (window.ONNIT_PAGE_DATA) {
                setPageData(window.ONNIT_PAGE_DATA);
            }
        }
    }, [window]);

    return {
        pageData,
        findProduct,
    };
}
