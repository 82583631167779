import React from "react";
import styled from "styled-components";
import Box, { BoxProps } from "../box/Box";
import { themeGet } from "@styled-system/theme-get";

interface Props extends BoxProps {}

const Svg = styled.svg<{ color?: string; width?: number }>`
    path {
        fill: ${p => themeGet(`colors.${p.color}`)(p)};
    }
`;

const OnnitLogo = ({ color = "black", width = 160,  pt = 2, pb = 1, ...rest }: Props) => {
    return (
        <Box width={width} pt={pt} pb={pb} {...rest}>
            <Svg
                color={color}
                viewBox="0 0 283.81 40.27"
                style={{ width: "100%" }}
            >
                <path d="M54.91,0H21.47a6.6,6.6,0,0,0-5.56,3.33l-14.57,25c-2,3.44-3.09,11.91,6.71,11.91H41.43a6.63,6.63,0,0,0,5.62-3.33L62.34,10.4C64.28,7.07,64.7,0,54.91,0ZM49.34,11.91,39.85,28.36a2.6,2.6,0,0,1-2.17,1.51H14c-1,0-.9-1-.54-1.51L23,11.91a2.64,2.64,0,0,1,2.36-1.51H48.86C49.59,10.4,49.71,11.31,49.34,11.91Z" />
                <path d="M130.06,0h-4.77a6.47,6.47,0,0,0-5.5,3.33L106.36,26.42c-.3.49-.6.24-.72,0L92.33,3.33C91.19,1.33,90,0,88.46,0H85.08a6.6,6.6,0,0,0-5.56,3.33L60.11,36.94c-1,1.64-.24,3.27,1.63,3.33h4.78a6.61,6.61,0,0,0,5.62-3.33L85.56,13.79a.4.4,0,0,1,.73,0L99.65,36.88c1.09,1.88,2.66,3.39,4,3.39h3.51a6.25,6.25,0,0,0,5.26-3.33L131.88,3.33A2.09,2.09,0,0,0,130.06,0Z" />
                <path d="M195.42,0h-4.77a6.47,6.47,0,0,0-5.5,3.33L171.72,26.42c-.3.49-.6.24-.72,0L157.69,3.33c-1.14-2-2.29-3.33-3.87-3.33h-3.38a6.6,6.6,0,0,0-5.56,3.33L125.47,36.94c-1,1.64-.24,3.27,1.63,3.33h4.78a6.61,6.61,0,0,0,5.62-3.33l13.42-23.15a.4.4,0,0,1,.73,0L165,36.88c1.09,1.88,2.66,3.39,4,3.39h3.51a6.25,6.25,0,0,0,5.26-3.33L197.24,3.33A2.08,2.08,0,0,0,195.42,0Z" />
                <path d="M220.7,0h-4.78a6.47,6.47,0,0,0-5.5,3.33L191,36.94c-1,1.64-.24,3.27,1.63,3.33h4.72A6.69,6.69,0,0,0,203,36.94L222.45,3.33A2.06,2.06,0,0,0,220.7,0Z" />
                <path d="M281.52,0H239.8A6.89,6.89,0,0,0,234,3.39s-2.18,3.87-2.24,3.87c-.9,1.69.18,3.14,2.06,3.08h14.57c.42,0,.54.3.24.85L233.7,36.94c-1,1.64-.18,3.27,1.69,3.33H240a6.78,6.78,0,0,0,5.74-3.33l15-25.75a1.46,1.46,0,0,1,1.27-.85h13.54A6.88,6.88,0,0,0,281.34,7s2.12-3.69,2.12-3.75A2.08,2.08,0,0,0,281.52,0Z" />
            </Svg>
        </Box>
    );
};

export default OnnitLogo;

const DashContainer = styled.svg<Props>`
    #bg {
        fill: ${p => themeGet(`colors.${String(p.bg)}`)(p)};
    }
    #squiggle {
        fill: ${p => themeGet(`colors.${p.color}`)(p)};
    }
`;

export const DashLogo = ({
    bg = "black",
    color = "white",
    width = 100,
    ...rest
}: Props) => {
    return (
        <Box width={width} {...rest}>
            <DashContainer
                viewBox="0 0 228.6 109.5"
                bg={bg}
                color={color}
                style={{ width: "100%" }}
            >
                <path
                    id="bg"
                    d="M209.6,0.1L59.4,0c-6.9,0-11.1,5.1-12.4,7.4L3,83c-4.4,7.7-6.9,26.5,14.9,26.5l150,0.1c3,0,8.8-1.2,12.5-7.4l45.7-78.9
                    C230.4,15.8,231.4,0.1,209.6,0.1z"
                />
                <path
                    id="squiggle"
                    d="M188.4,32.4l-26,44.9c-1,1.5-3.1,4.4-7,4.4h-4.7c-1.8,0-3.9-2-5.3-4.5l-17.8-30.8
                    c-0.2-0.4-0.7-0.4-1,0l-17.8,30.9c-1,1.5-3.1,4.4-7,4.4H97c-1.8,0-3.9-2-5.3-4.5L73.9,46.3c-0.2-0.4-0.7-0.4-1,0L55,77.3
                    c-2.3,3.7-5.7,4.4-7.5,4.4c0,0-6.3,0-6.4,0c-2.5-0.1-3.5-2.3-2.2-4.4l25.9-44.9c0.7-1.4,3.3-4.4,7.4-4.4h4.5c2.1,0,3.6,1.8,5.2,4.4
                    l17.8,30.8c0.2,0.3,0.6,0.6,1,0l17.8-30.8c0.8-1.4,3.3-4.4,7.4-4.4h4.5c2.1,0,3.6,1.8,5.2,4.4l17.8,30.8c0.2,0.3,0.6,0.6,1,0
                    l17.9-30.8c2.3-3.7,5.6-4.4,7.3-4.4c0,0,6.3,0,6.4,0C188.5,28,189.7,30.2,188.4,32.4z"
                />
            </DashContainer>
        </Box>
    );
};
