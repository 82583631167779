import React, { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import Box, {BoxProps} from "@onnit-js/ui/components/box/Box";
import Text from "@onnit-js/ui/components/text/Text";
import Product from "@onnit-js/ui/@types/interfaces/product/BuyBoxProduct";
import { MoneyFormat } from "../../../lib/utils";
import AddToCartButton from "../AddToCartButton";
import StarRating from "@onnit-js/ui/components/rating/StarRating";
import SubscriptionInfoModal from "@onnit-js/ui/components/buybox/shared/SubscriptionInfoModal";

const Container = styled(Box)<{ isOffset: boolean }>`
    @media (min-width: ${(p) => p.theme.breakpoints[2]}) {
        > div:nth-child(1),
        > div:nth-child(3) {
            margin-top: ${(p) => (p.isOffset ? p.theme.space[9] : 0)}px;
        }
    }
`;

interface OffersProps extends BoxProps {
    children: ReactNode;
}

export function Offers({ children, ...rest }: OffersProps) {
    return (
        <Container
            isOffset={React.Children.count(children) === 3}
            {...rest}
            display={["block", "block", "block", "flex"]}
            justifyContent="center"
            alignItems="flex-start"
            p={4}
        >
            {children}
        </Container>
    );
}

/**
 * Subscription Toggle
 */
interface SubscriptionToggleProps extends BoxProps {
    selected: boolean;
    label: string;
    product: Product;
    onProductSelect: (product: Product) => void;
}

function SubscriptionToggle({ product, onProductSelect, selected = false, label, ...rest }: SubscriptionToggleProps) {
    return (
        <Box
            {...rest}
            py={3}
            px={5}
            border="2px solid"
            borderColor={selected ? "#FC5D3E" : "grays.2"}
            borderRadius="0 4px 4px 4px"
            tabIndex={0}
            style={{ cursor: "pointer" }}
            onClick={() => onProductSelect(product)}
            onKeyUp={(e) => {
                e.preventDefault();
                if (e.key === "Enter") {
                    onProductSelect(product);
                }
            }}
        >
            <Text fontWeight="heavy" fontSize={4} textAlign="center">
                {product.price}
            </Text>
            <Text fontSize="9px" fontWeight="bold" color="grays.5" textTransform="uppercase" textAlign="center">
                {label}
            </Text>
        </Box>
    );
}

interface SubscriptionToggleGroupProps extends BoxProps {
    selectedProduct: Product;
    products: Product[];
    onProductSelect: (product: Product) => void;
}

function SubscriptionToggleGroup({
    selectedProduct,
    products,
    onProductSelect,
    ...rest
}: SubscriptionToggleGroupProps) {
    if (products.length > 2) {
        console.error("SubscriptionToggleGroup cannot accept more than 2 products");
        return null;
    }
    const normalProduct = products.filter((p) => !isSubscriptionProduct(p))[0];
    const subscriptionProduct = products.filter(isSubscriptionProduct)[0];

    useEffect(() => {
        // Select subscription product on mount.
        onProductSelect(subscriptionProduct);
    }, [subscriptionProduct]);

    return (
        <Box {...rest} display="flex" justifyContent="center" alignItems="flex-end" role="radiogroup">
            <SubscriptionToggle
                role="radio"
                aria-checked={selectedProduct === normalProduct ? "true" : "false"}
                selected={selectedProduct === normalProduct}
                label="One Time"
                onProductSelect={onProductSelect}
                product={normalProduct}
                m={1}
            />
            <Box m={1} role="radio" aria-checked={selectedProduct === subscriptionProduct ? "true" : "false"}>
                <Box
                    display="inline-block"
                    borderRadius="4px 4px 0 0"
                    bg={selectedProduct === subscriptionProduct ? "#FC5D3E" : "grays.2"}
                    pt={1}
                    px={3}
                >
                    <Text fontSize="11px" fontWeight="bold" textTransform="uppercase" textAlign="center">
                        15% OFF
                    </Text>
                </Box>
                <SubscriptionToggle
                    selected={selectedProduct === subscriptionProduct}
                    label="Subscription"
                    onProductSelect={onProductSelect}
                    product={subscriptionProduct}
                />
            </Box>
        </Box>
    );
}

function SubscriptionInfoText({ product }: { product: Product }) {
    let message = "Rebills";
    //prettier-ignore
    message += product.meta["days-before-rebill"] !== product.meta["rebill-convert-days"] ? ` in ${product.meta["days-before-rebill"]} days, then ` : '';
    message += ` every ${product.meta["rebill-convert-days"]} days for ${product.price}`;
    return (
        <>
            <Text fontSize="11px" color="grays.4" textAlign="center">
                {message}.
            </Text>
            <Text
                fontSize="11px"
                color="grays.4"
                textAlign="center"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                Cancel anytime. <SubscriptionInfoModal size={16} />
            </Text>
        </>
    );
}

/**
 * Offer Item
 */
interface OfferProps extends BoxProps {
    children?: ReactNode;
    title: string;
    products: Product[];
    showPriceInTitle?: boolean;
    packageQuantity: number;
    containerType: string;
    servingSize?: string;
    buyBtnText?: string;
    rating?: number;
    numReviews?: string;
    offerFinePrintText?: string;
}

export function Offer({
    children,
    title,
    showPriceInTitle = true,
    products,
    packageQuantity,
    containerType,
    servingSize,
    buyBtnText,
    rating,
    numReviews,
    offerFinePrintText,
    ...rest
}: OfferProps) {
    const [selectedProduct, setProduct] = useState<Product>(products[0]);
    const createsSubscription = products.some(isSubscriptionProduct);
    const savings = parseFloat(selectedProduct.price_msrp_raw) - parseFloat(selectedProduct.price_raw);

    return (
        <Box
            bg="white"
            border="10px solid"
            p={4}
            mb={4}
            mx={[2, "auto", "auto", 2]}
            flex="1 1 auto"
            maxWidth={["100%", "100%", "448px"]}
            {...rest}
        >
            <Text fontWeight="black" fontSize={[5, 6, 7]} textAlign="center" fontStyle="italic">
                {title}
                {showPriceInTitle ? ` - ${products[0].price}` : undefined}
            </Text>

            {children}

            <Box mb={2}>
                <Text fontWeight="heavy" fontSize={[5, 5, 6]} textAlign="center">
                    {MoneyFormat.format(parseFloat(selectedProduct.price_raw) / packageQuantity)}/{containerType}
                </Text>
                {!createsSubscription && savings > 0 && (
                    <Text
                        fontWeight="bold"
                        color="#B40404"
                        fontSize={[4, 4, 5]}
                        textAlign="center"
                        fontStyle="italic"
                        letterSpacing="-0.03em"
                    >
                        You save {MoneyFormat.format(savings)}
                    </Text>
                )}
                {servingSize && <Text textAlign="center">{servingSize}</Text>}
            </Box>

            {createsSubscription && (
                <SubscriptionToggleGroup
                    mt={3}
                    selectedProduct={selectedProduct}
                    products={products}
                    onProductSelect={setProduct}
                />
            )}

            <Box display="flex" justifyContent="center" py={4}>
                <AddToCartButton
                    size="medium"
                    buyBtnText={isSubscriptionProduct(selectedProduct) ? "SUBSCRIBE & SAVE" : buyBtnText}
                    inStock={selectedProduct.in_stock}
                    configs={[
                        {
                            product_id: selectedProduct.id,
                            quantity: 1,
                            subscription_interval: null, // Offer sub products are packages which cannot be subscribed to, but will create a subscription from the cart.
                        },
                    ]}
                />
            </Box>

            {rating && (
                <Box display="flex" justifyContent="center" alignItems="center" p={2}>
                    <StarRating rating={rating} mr={2} />
                    {numReviews && (
                        <Text textTransform="uppercase" fontSize={1} fontWeight="bold" mt="1px">
                            &mdash; {numReviews} Reviews
                        </Text>
                    )}
                </Box>
            )}

            {createsSubscription && isSubscriptionProduct(selectedProduct) && (
                <SubscriptionInfoText product={selectedProduct} />
            )}

            {offerFinePrintText && (
                <Text
                    fontSize="11px"
                    color="grays.4"
                    mt={1}
                    textAlign="center"
                    dangerouslySetInnerHTML={{ __html: offerFinePrintText }}
                />
            )}
        </Box>
    );
}

/**
 * utils
 */
function isSubscriptionProduct(product: Product): boolean {
    return Boolean(product.meta["days-before-rebill"]) || Boolean(product.meta["rebill-convert-days"]);
}
