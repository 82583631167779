import React, { ImgHTMLAttributes, useState } from "react";
import styled from "styled-components";
import * as SS from "@techstack/styled-system";
import { useInView } from "react-intersection-observer";
import { lazyOptions, placeholderSrc } from "./shared";

export interface ImageProps extends ImgHTMLAttributes<HTMLImageElement>, SS.SpaceProps {}

const Image = styled.img<ImageProps>`
    box-sizing: border-box;
    ${SS.space};
`;

interface LazyImageProps extends Omit<ImageProps, "placeholder"> {
    placeholder?: [number | string, number | string];
}

const LazyImage = ({ src, style, placeholder, width, height, ...rest }: LazyImageProps) => {
    const [ref, inView] = useInView(lazyOptions);
    const [isLoaded, setLoaded] = useState(false);
    const styles = {
        transition: "opacity 300ms",
        opacity: isLoaded ? 1 : 0,
        display: "block",
        width: "100%",
        height: "auto",
        ...style,
    };

    let w = width;
    let h = height;

    if (placeholder && placeholder[0]) {
        w = placeholder[0];
    }

    if (placeholder && placeholder[1]) {
        h = placeholder[1];
    }

    return (
        <Image
            ref={ref}
            width={w}
            height={h}
            {...rest}
            src={inView ? src : placeholder ? placeholderSrc(placeholder) : ""}
            style={styles}
            onLoad={inView ? () => setLoaded(true) : () => {}}
        />
    );
};

export interface ImgProps extends LazyImageProps {
    lazyLoad?: boolean;
}

export default ({ lazyLoad = true, placeholder, style, ...rest }: ImgProps) =>
    lazyLoad ? (
        <LazyImage placeholder={placeholder} style={style} {...rest} />
    ) : (
        <Image style={{ display: "block", width: "100%", height: "auto", ...style }} {...rest} />
    );
