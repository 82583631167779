import React from "react";
import Box, { BoxProps } from "../box/Box";
import Img, { ImgProps } from "../image/Image";

export interface PageHeaderProps extends BoxProps {
    bgImageSrc: string;
    contentImageProps?: ImgProps;
}

const PageHeader: React.FC<React.PropsWithChildren<PageHeaderProps>> = ({ bgImageSrc, contentImageProps }) => {
    return (
        <Box display="grid" className="oui-page-header">
            <Box gridColumn={"1 / -1"} gridRow={1} zIndex={1}>
                <Img lazyLoad={false} src={bgImageSrc} alt="" aria-hidden={true} />
            </Box>
            {contentImageProps && (
                <Box
                    gridColumn={"1 / -1"}
                    gridRow={1}
                    zIndex={2}
                    display="flex"
                    justifyContent="center"
                    py={[6, 6, 6, 8]}
                    px={8}
                    maxWidth={["360px", "360px", "360px", "540px"]}
                    mx="auto"
                >
                    <Img {...contentImageProps} />
                </Box>
            )}
        </Box>
    );
};

export default PageHeader;
