import React, { useEffect, useState } from "react";
import Button from "../../button/Button";
import Box from "../../box/Box";
import Product from "../../../@types/interfaces/product/BuyBoxProduct";
import BuyBoxFooter from "../shared/BuyBoxFooter";
import SubscriptionRadioButton from "./SubscriptionRadioButton";
import { useAddToBag } from "../../../hooks/useAddToBag";
import { CartProductConfig } from "../../../@types/interfaces/CartProductConfig";
import { RadioGroup } from "../../form/toggle/RadioGroup";
import PromoDiscountConfigs from "../../../@types/interfaces/PromoDiscountConfig";
import Text from "../../text";
import { ProductStatus } from "../../../@types/enums/ProductStatus";
import { getProductStatus } from "../utils";
import OOSView from "../../buybox/shared/OOSView";
import DiscontinuedView from "../../buybox/shared/DiscontinuedView";

interface ViewProps {
    selectedProduct: Product;
    products: Product[];
    onProductChange?: (product: Product) => void;
    freeShippingThreshold: number;
    onSuccess?: () => void;
    onError?: (error: any) => void;
    addToBagMiddleware?: (config: CartProductConfig) => Promise<CartProductConfig>;
    showFooter?: boolean;
    promoDiscountConfigs?: PromoDiscountConfigs[] | null;
    buyOnceProducts?: { [product: number]: Product } | null;
}

const ProductSelectView: React.FC<React.PropsWithChildren<ViewProps>> = ({
                                                    selectedProduct,
                                                    products,
                                                    onProductChange,
                                                    freeShippingThreshold,
                                                    onSuccess,
                                                    onError,
                                                    children,
                                                    addToBagMiddleware,
                                                    showFooter = true,
                                                    promoDiscountConfigs,
                                                    buyOnceProducts,
                                                }) => {

    // -----[ Hooks ]-----
    const { isAddingToBag, addToBag } = useAddToBag({ onSuccess, onError });
    const [subscriptionInterval, setSubscriptionInterval] = useState<number | null>(null);
    const [adjustedPrice, setAdjustedPrice] = useState<number>(0);
    const [radioState, setRadioState] = useState<any>((""));
    const [selectedProductStatus, setSelectedProductStatus] = useState<ProductStatus | null>(null);
    const [selectedBuyOnceProductStatus, setSelectedBuyOnceProductStatus] = useState<ProductStatus | null>(null);
    const [selectedBuyOnceProduct, setSelectedBuyOnceProduct] = useState<Product | null>(null);

    useEffect(() => {
        setSelectedProductStatus(getProductStatus(selectedProduct));
        // Update default subscription interval when product changes.
        setSubscriptionInterval(Number(selectedProduct.meta["default-subscription-frequency"]));
        if (buyOnceProducts && buyOnceProducts[selectedProduct.id]) {
            setSelectedBuyOnceProduct(buyOnceProducts[selectedProduct.id]);
            setSelectedBuyOnceProductStatus(getProductStatus(buyOnceProducts[selectedProduct.id]));
        } else {
            setSelectedBuyOnceProduct(null);
            setSelectedBuyOnceProductStatus(null);
        }
        setRadioState(selectedProduct.id);

        // Update Onnit X Rewards points when product configuration changes.
        let adjustedPrice = parseFloat(selectedProduct.price_raw);
        const subscriptionEligible = selectedProduct.meta["subscription-eligible"] === "yes" ?? false;
        const subscriptionPrice = selectedProduct.price_subscription_raw || 0.0;
        if (subscriptionEligible) {
            adjustedPrice = subscriptionPrice;
        }
        setAdjustedPrice(adjustedPrice);
    }, [selectedProduct]);

    return (
        <>
            <Box>
                <RadioGroup
                    labelPosition="left"
                    value={radioState}
                    onChange={({ target: { value } }) => {
                        setRadioState(value);
                        const product = products.find((p) => p.id === parseInt(value));
                        if (product) {
                            onProductChange && onProductChange(product);
                        }
                    }}
                >
                    {products.map((product) => {
                        {
                            return (
                                <SubscriptionRadioButton
                                    key={product.name}
                                    product={product}
                                    freeShippingThreshold={freeShippingThreshold}
                                    promoDiscountConfigs={promoDiscountConfigs}
                                />
                            )
                        }
                    })}
                </RadioGroup>

                {selectedProductStatus === ProductStatus.InStock &&
                    (
                        <Button
                            mt={5}
                            width={1}
                            display="block"
                            style={{ padding: "22px 8px 18px" }}
                            p={0}
                            onClick={() => {
                                const config = {
                                    product_id: selectedProduct.id,
                                    quantity: 1,
                                    subscription_interval: subscriptionInterval,
                                };

                                if (addToBagMiddleware) {
                                    addToBagMiddleware(config)
                                        .then((newConfig) => {
                                            addToBag(newConfig);
                                        })
                                        .catch(console.error);
                                } else {
                                    addToBag(config);
                                }
                            }}
                            disabled={isAddingToBag}
                        >
                            {isAddingToBag
                                ? "Adding..."
                                : `Add to Bag & Subscribe`
                            }
                        </Button>
                    )
                }

                {selectedProductStatus === ProductStatus.OutOfStock && (
                    <OOSView product={selectedProduct} />
                )}

                {selectedProductStatus === ProductStatus.Discontinued && (
                    <DiscontinuedView />
                )}
            </Box>

            {selectedBuyOnceProduct && selectedBuyOnceProductStatus === ProductStatus.InStock && (
                <>
                    <Text as="button"
                          mt={6}
                          mx="auto"
                          fontWeight="bold"
                          textTransform="uppercase"
                          onClick={() => {
                              const config = {
                                  product_id: selectedBuyOnceProduct.id,
                                  quantity: 1,
                                  subscription_interval: null,
                              };
                              if (addToBagMiddleware) {
                                  addToBagMiddleware(config)
                                      .then((newConfig) => {
                                          addToBag(newConfig);
                                      })
                                      .catch(console.error);
                              } else {
                                  addToBag(config);
                              }
                          }}
                    >
                        {isAddingToBag
                            ? "Adding to bag..."
                            : `Buy once for ${selectedBuyOnceProduct.price} + ${(parseFloat(selectedBuyOnceProduct.price_raw) > freeShippingThreshold) ? ' free ' : ''} shipping`
                        }
                    </Text>
                    {parseFloat(selectedBuyOnceProduct.price) < parseFloat(selectedBuyOnceProduct.price_msrp) &&
                        (<Text textAlign="center" color="grays.6"
                               fontSize={1}>Normally, {selectedBuyOnceProduct.price_msrp}</Text>)
                    }
                </>
            )}

            {children}

            {showFooter && (
                <BuyBoxFooter
                    adjustedPrice={adjustedPrice}
                    freeShippingThreshold={freeShippingThreshold}
                />
            )}
        </>
    );
};
export default ProductSelectView;
