import React, { ReactNode, useState } from "react";
import Text from "../text/Text";
import Button from "../button/Button";
import TextField from "./textfield/TextField";
import { CheckBox } from "./toggle";
import Box, { BoxProps } from "../box/Box";
import {isValidEmail} from "../../utils/utils";
import useRemoteDataState, {RemoteDataTypes} from "../../hooks/useRemoteData";

interface Props extends BoxProps {
    pid: number;
    onSignup?: (data: FormData) => void;
    formMessage?: ReactNode;
}

interface FormProps {
    handleSubmit: (payload: { email: string; optin: string }) => void;
    isSubmitting: boolean;
    formMessage?: ReactNode;
}

const Form = ({ formMessage, isSubmitting, handleSubmit }: FormProps) => {
    const [email, setEmail] = useState("");
    const [optin, setOptin] = useState("1");

    return (
        <>
            {formMessage ? (
                formMessage
            ) : (
                <Text as="p" color="grays.4" mb={4} display="block">
                    Sign up here and we will notify you the glorious moment this product arrives.
                </Text>
            )}
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit({ email, optin });
                }}
            >
                <TextField
                    type="email"
                    name="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                />
                <CheckBox
                    name="newsletter"
                    checked={optin === "1"}
                    value={1}
                    onChange={({ target: { checked } }) => {
                        setOptin(checked ? "1" : "0");
                    }}
                    label={(
                        <Box>
                            <Text
                                mt={1}
                                color="grays.5"
                                typeStyle="caption02"
                            >
                                Yes, I want to receive offers and updates
                                from Onnit and other related brands via email and online
                                marketing. I have read and acknowledge Onnit's{" "}
                                <Text as="a" display="inline" href="/privacy-policy/" target="_blank">Privacy Policy</Text> and{" "}
                                <Text as="a" display="inline" href="/financial-incentives-notice/" target="_blank">Notice of Financial Incentive</Text>.
                                I understand I may unsubscribe from promotional emails at any time.*
                            </Text>
                            <Text
                                mt={2}
                                color="grays.5"
                                typeStyle="caption02"
                                fontStyle="italic"
                                style={{
                                    fontSize: "12px"
                                }}
                            >
                                *If you unsubscribe, you may still receive non-marketing or transactional messages from us.
                            </Text>
                        </Box>
                    )}
                />
                <Button type="submit" width={1} mt={4} disabled={isSubmitting}>
                    {isSubmitting ? "Sending..." : "Let me know"}
                </Button>
            </form>
        </>
    );
};
function StockNotificationForm({ pid, onSignup, formMessage, ...rest }: Props) {
    const [remoteData, dispatch] = useRemoteDataState();

    const handleSubmit = (values: { email: string; optin: string }) => {
        if (isValidEmail(values.email)) {
            dispatch({ type: RemoteDataTypes.LOADING });

            const formData = new FormData();
            formData.append("pid", pid.toString());
            formData.append("email", values.email);
            formData.append("optin", values.optin);

            fetch("/onnit/req/stock-notification.php", {
                method: "POST",
                body: formData,
            })
                .then((resp) => {
                    if (!resp.ok) {
                        return resp.json().then((text) => {
                            throw new Error(text.message.toString());
                        });
                    }
                    return resp.json();
                })
                .then((resp) => {
                    dispatch({
                        type: RemoteDataTypes.SUCCESS,
                        payload: resp.message,
                    });

                    if (values.optin === "1" && window.ONNIT && window.ONNIT.hubspot) {
                        window.ONNIT.hubspot.emailListSignup(
                            values.email,
                            ["list_onnit_newsletter"],
                            "onnit:stock-notify"
                        );
                    }

                    onSignup?.(formData);
                })
                .catch((e: string) => {
                    dispatch({
                        type: RemoteDataTypes.FAILURE,
                        payload: e,
                    });
                    console.debug(e);
                    return Promise.reject();
                })
                .catch(function onReject() {
                    // expected rejection from API
                });
        }
    };

    return (
        <Box {...rest}>
            <Text as="h2" fontSize={5} fontWeight="bold" mb={3}>
                Out of stock
            </Text>
            {remoteData.type === RemoteDataTypes.SUCCESS ? (
                <Text role="alert">{remoteData.data}</Text>
            ) : (
                <>
                    <Form
                        formMessage={formMessage}
                        handleSubmit={handleSubmit}
                        isSubmitting={remoteData.eq(RemoteDataTypes.LOADING)}
                    />
                    {remoteData.type === RemoteDataTypes.FAILURE && (
                        <Text color="accent.0.4" mt={2} p={2} role="alert">
                            {remoteData.errors.message}
                        </Text>
                    )}
                </>
            )}
        </Box>
    );
}

export default StockNotificationForm;
