import React from "react";

function useRovingIndex(
    maxIndex: number,
    initialIndex = 0,
    doWrap = true
): [number, (nextIndex: number) => void, Function, Function] {
    const [currentIndex, setIndex] = React.useState(initialIndex);

    function decrementIndex() {
        if (currentIndex === 0 && doWrap) {
            setIndex(maxIndex);
            return;
        }
        if (currentIndex > 0) {
            setIndex(i => i - 1);
            return;
        }
    }

    function incrementIndex() {
        if (currentIndex === maxIndex && doWrap) {
            setIndex(0);
            return;
        }
        if (currentIndex < maxIndex) {
            setIndex(i => i + 1);
            return;
        }
    }

    function updateIndex(nextIndex: number) {
        //check if next index is in bounds.
        if (nextIndex >= 0 && nextIndex <= maxIndex) {
            setIndex(nextIndex);
        }
    }

    return [currentIndex, updateIndex, decrementIndex, incrementIndex];
}

export default useRovingIndex;
