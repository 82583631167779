import React from "react";
import Text, { TextProps } from "../text/Text";
import Box, { BoxProps } from "../box/Box";

//Note: set to="/" and as={NavLink} to make this a React Router link.
//TODO: look into conditional types.  if to is provided, as is required.

interface Props extends BoxProps {
    href?: string;
    to?: string;
    as?: any;
    target?: "_blank" | "_self" | "_parent" | "_top";
    textProps?: TextProps;
}

export default ({ children, color = "black", as, to, href, target, textProps, ...rest }: Props) => {
    let linkProps = {};
    if (href) {
        linkProps = {
            href,
            target,
            as: "a",
        };
    } else if (to) {
        linkProps = {
            as,
            to,
        };
    }

    return (
        <Box display="flex" alignItems="flex-start" {...rest}>
            <Box bg={color} height="2px" width="12%" minWidth="80px" maxWidth="160px" mt="0.65em" mr={4} />
            <Text
                typeStyle="copy03"
                color={color}
                {...linkProps}
                {...textProps}
                style={{ textDecoration: "none" }}
                lineHeight="1.15">
                {children}
            </Text>
        </Box>
    );
};
