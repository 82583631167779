import React, { useMemo } from "react";
import Box, { BoxProps } from "../../box/Box";
import Text from "../../text";
import PromoDiscountConfig from "../../../@types/interfaces/PromoDiscountConfig";

interface Props extends BoxProps {
    promoDiscountConfig: PromoDiscountConfig;
}

export function getActivePromoDiscountConfig(pid: number, promoDiscountConfigs: PromoDiscountConfig[]) {
    return promoDiscountConfigs.find(
        (promoConfig: PromoDiscountConfig) =>
            promoConfig.eligible_promo_product_ids.includes(pid) ||
            promoConfig.eligible_discount_product_ids.includes(pid)
    );
}

const PromoDiscount: React.FC<React.PropsWithChildren<Props>> = ({ promoDiscountConfig, ...rest }) => {
    return (
        <Box {...rest} p={5} borderRadius={4} border="2px solid" borderColor="riverTeals.6" bg="riverTeals.0">
            <Text
                display="block"
                fontWeight="heavy"
                color="riverTeals.6"
                as="p"
                fontSize={3}
                mb={3}
                dangerouslySetInnerHTML={{ __html: promoDiscountConfig.title }}
            />
            <Text
                typeStyle="body"
                fontSize={1}
                color="grays.6"
                dangerouslySetInnerHTML={{ __html: promoDiscountConfig.description }}
            />
        </Box>
    );
};

export default PromoDiscount;
