import {ProductBox} from "../../interfaces/ProductBox";

const nightPackIngredients: ProductBox[] = [
    {
        title: 'New MOOD<span class="oui-sup">&reg;</span>',
        content: 'Our stress-response formula, it contains Valerian root, lemon balm, and other ingredients that support serotonin production and promote relaxation.<span class="oui-sup">&dagger;</span>',
        img: "new-mood--ingredient.png",
        altText: "Bottle of New MOOD&reg; on desk",
    },
    {
        title: 'VIRUTech<span class="oui-sup">&reg;</span>',
        content: 'This formulation includes Vitamin C, selenium, and zinc, offering immune system support that acts like a suit of armor against germs.<span class="oui-sup">&dagger;</span>',
        img: "virutech--ingredient.png",
        altText: "Bottle of VIRUTech® in front of fresh ingredients",
    },
    {
        title: 'Key Minerals<span class="oui-sup">&reg;</span>',
        content: 'Boron, calcium, iodine, magnesium, and molybdenum provide total body support and optimization.<span class="oui-sup">&dagger;</span>',
        img: "key-minerals--ingredient.png",
        altText: "Man holding bottle of Key Minerals®",
    },
    {
        title: 'Spirulina &amp; Chlorella',
        content: 'These quintessential green superfoods are blended in this supercombo, packing huge micronutrition that supports the immune system, endurance, and heart health.<span class="oui-sup">&dagger;</span>',
        img: "spirulina--ingredient.png",
        altText: "Hand holding bottle of Spirulina & Chlorella",
    },
    {
        title: 'Shroom Tech<span class="oui-sup">&reg;</span> IMMUNE',
        content: 'A potent blend of nutritional mushrooms, herbs, and turmeric extract to support the innate immune system.<span class="oui-sup">&dagger;</span>',
        img: "shroom-tech-immune--ingredient.png",
        altText: "Bottle of Shroom Tech® IMMUNE in front of fresh fruits and vegetables",
    },
    {
        title: 'Krill Oil',
        content: 'Packed with EPA &amp; DHA, Krill Oil supplies essential fatty acids from a clean, sustainable source that also contains the powerful carotenoid astaxanthin.<span class="oui-sup">&dagger;</span>',
        img: "krill--ingredient.png",
        altText: "Bottle of Krill Oil on ocean cliff",
    }
];

export default nightPackIngredients;