import React from "react";
import SelectField, { SelectFieldProps } from "../../../form/selectfield/SelectField";

interface QuantitySelectProps extends Omit<SelectFieldProps, "ref"> {
    cartQuantityMax?: number | null;
    label?: string;
}

function QuantitySelect({ cartQuantityMax = 5, label = "Quantity", ...rest }: QuantitySelectProps) {
    const maxQuantity = cartQuantityMax !== null && cartQuantityMax > 0 ? cartQuantityMax : 5;

    return (
        <SelectField label={label} {...rest}>
            {Array.from({ length: maxQuantity }, (_, i) => (
                <option key={`oui_option_${i}`} value={i + 1}>
                    {i + 1}
                </option>
            ))}
        </SelectField>
    );
}

export default QuantitySelect;
