import { useState, useEffect } from 'react';
import OnnitContext from '../@types/interfaces/OnnitContext';

export default function useOnnitContext() {
    const [onnitContext, setOnnitContext] = useState<OnnitContext | null>(null);

    useEffect(() => {
        if (window) {
            if (window.onnit_context) {
                setOnnitContext(window.onnit_context);
            }
        }
    }, []);

    return onnitContext ? onnitContext : null;
}
