import Box, {BoxProps} from "../../../box/Box";
import styled from "styled-components";
import { theme, prop } from "styled-tools";

export const TwelveColumn = styled(Box)<BoxProps>`
    display: ${prop("display", "grid")};
    max-width: ${theme("gridMaxWidth")}px;
    margin-left: auto;
    margin-right: auto;
    grid-auto-columns: 1fr;
    grid-template-rows: ${prop("gridTemplateRows", "auto")};
    @media (min-width: ${p => p.theme.breakpoints[1]}) {
        grid-template-columns: repeat(12, 1fr);
        grid-auto-flow: column;
    }
`;

export default TwelveColumn;
