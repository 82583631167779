import React, { useState } from "react";
import { CartProductConfig } from "../../interfaces/CartProductConfig";
import Button, {ButtonProps} from "@onnit-js/ui/components/button/Button";

interface Props extends ButtonProps {
    configs: CartProductConfig[];
    buyBtnText?: string;
    buttonColor?: string;
    buttonTextColor?: string;
    inStock?: boolean;
    onSuccess?: () => void;
    onError?: () => void;
}

const AddToCartButton: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
    configs,
    inStock = true,
    size = "medium",
    buyBtnText = "Add to Bag",
    buttonColor = "black",
    buttonTextColor = "white",
    onSuccess,
    onError,
    ...rest
}) => {
    const [isAddingToCart, setAddingToCart] = useState(false);

    const addToCart = () => {
        // Push this check to when the user clicks the button
        // because ONNIT.cart is defined after this app bootstraps.
        if (!window.ONNIT || !window.ONNIT.cart) {
            throw new Error("ONNIT.cart is undefined");
        }

        setAddingToCart(true);

        window.ONNIT.cart
            .addProducts(configs)
            .then(() => {
                if (onSuccess) {
                    onSuccess();
                    return;
                }
                window.ONNIT && window.ONNIT.cart && window.ONNIT.cart.openDrawer();
            })
            .catch((error) => {
                console.error("Failed to add product to cart.", error);
                onError && onError();
            })
            .finally(() => setAddingToCart(false));
    };

    return !inStock ? (
        <Button disabled={true} size={size} {...rest}>
            Out of Stock
        </Button>
    ) : (
        <Button
            color={buttonColor}
            textColor={buttonTextColor}
            width={[1, "auto"]}
            size={size}
            onClick={(e) => {
                e.preventDefault();
                addToCart();
            }}
            disabled={isAddingToCart}
            {...rest}>
            {isAddingToCart ? "Adding..." : buyBtnText}
        </Button>
    );
};

export default AddToCartButton;
