import React, {ReactNode} from 'react';
import Box, {BoxProps} from '../../box/Box';
import Text from '../../text';

interface Props extends BoxProps {
    label: ReactNode;
    value: ReactNode;
}

function ReadOnly({label, value, my = 2, ...rest}: Props) {
    return (
        <Box border="2px solid" borderColor="grays.1" borderRadius="4px" px="11px" pt="5px" pb="9px" my={my} {...rest}>
            <Text fontSize="14px" color="grays.4" mb="10px">
                {label}
            </Text>
            <Text>{value}</Text>
        </Box>
    );
}

export default ReadOnly;
