import React, { useEffect, useState } from "react";
import Button from "../../../button/Button";
import Box from "../../../box/Box";
import Product from "../../../../@types/interfaces/product/BuyBoxProduct";
import BuyBoxFooter from "../../shared/BuyBoxFooter";
import {getUrlParameter} from "../../../../utils/utils";
import SubscriptionEligible from "./SubscriptionEligible";
import NonSubscriptionEligible from "./NonSubscriptionEligible";
import {useAddToBag} from "../../../../hooks/useAddToBag";
import {ProductType} from "../../../../@types/enums/ProductType";
import {CartProductConfig} from "../../../../@types/interfaces/CartProductConfig";
import {ProductCategory} from "../../../../@types/enums/ProductCategory";
import PromoDiscountConfig, {PromoDiscountTypeEnum} from "../../../../@types/interfaces/PromoDiscountConfig";

interface ViewProps {
    product: Product;
    freeShippingThreshold: number;
    onSuccess?: () => void;
    onError?: (error: any) => void;
    addToBagMiddleware?: (config: CartProductConfig) => Promise<CartProductConfig>;
    showFooter?: boolean;
    promoDiscountConfig?: PromoDiscountConfig | null;
}

const InStockView: React.FC<React.PropsWithChildren<ViewProps>> = ({
    product,
    freeShippingThreshold,
    onSuccess,
    onError,
    children,
    addToBagMiddleware,
    showFooter = true,
    promoDiscountConfig,
}) => {
    const isOnSale = product.sale_discount_ratio > 0;
    const saleText = isOnSale ? `${(product.sale_discount_ratio * 100).toFixed()}% off` : "";
    const isDigital = product.type_id === ProductType.DIGITAL;
    const isFitness = product.meta.category === ProductCategory.FITNESS;

    // -----[ Subscription ]-----
    const subscriptionEligible = product.meta["subscription-eligible"] === "yes";
    const subscriptionIntervalDefault = Number(product.meta["default-subscription-frequency"]) || 30;
    let subscriptionPrice = product.price_subscription_raw || 0.0;
    let subscriptionPercentOff;
    if (promoDiscountConfig?.promo_discounts_type === PromoDiscountTypeEnum.SUBSCRIPTION) {
        subscriptionPrice =
            (1 - promoDiscountConfig.unit_discount_ratio) * parseFloat(product.price_msrp_raw);
        subscriptionPercentOff = promoDiscountConfig.unit_discount_ratio * 100;
    }

    // -----[ Hooks ]-----
    const { isAddingToBag, addToBag } = useAddToBag({ onSuccess, onError });
    const [subscriptionInterval, setSubscriptionInterval] = useState(subscriptionIntervalDefault);
    const [doSubscribe, setDoSubscribe] = useState(() => subscriptionEligible);
    const [quantity, setQuantity] = useState(1);
    const [adjustedPrice, setAdjustedPrice] = useState(0);

    //check the autosub query param
    useEffect(() => {
        getUrlParameter("autosub") === "0" && setDoSubscribe(false);
    }, []);

    // Update default subscription interval when product changes.
    useEffect(() => {
        setSubscriptionInterval(Number(product.meta["default-subscription-frequency"]));
    }, [product]);

    // Update Onnit X Rewards points when product configuration changes.
    useEffect(() => {
        let adjustedPrice = parseFloat(product.price_raw);
        if (subscriptionEligible) {
            adjustedPrice = doSubscribe ? subscriptionPrice : parseFloat(product.price_raw);
        }

        setAdjustedPrice(adjustedPrice * quantity);
    }, [product, doSubscribe, quantity]);

    return (
        <>
            <Box>
                {subscriptionEligible ? (
                    <SubscriptionEligible
                        doSubscribe={doSubscribe}
                        setDoSubscribe={setDoSubscribe}
                        subscriptionPrice={subscriptionPrice}
                        subscriptionPercentOff={subscriptionPercentOff}
                        subscriptionIntervalDefault={subscriptionIntervalDefault}
                        subscriptionInterval={subscriptionInterval}
                        setSubscriptionInterval={setSubscriptionInterval}
                        saleText={saleText}
                        product={product}
                        quantity={quantity}
                        setQuantity={setQuantity}
                        isOnSale={isOnSale}
                        isDigital={isDigital}
                    />
                ) : (
                    <NonSubscriptionEligible
                        product={product}
                        quantity={quantity}
                        setQuantity={setQuantity}
                        isDigital={isDigital}
                        isOnSale={isOnSale}
                        saleText={saleText}
                    />
                )}

                <Button
                    mt={5}
                    width={1}
                    display="block"
                    style={{ padding: "22px 8px 18px" }}
                    p={0}
                    onClick={() => {
                        const config = {
                            product_id: product.id,
                            quantity: quantity,
                            subscription_interval: doSubscribe ? subscriptionInterval : null,
                        };

                        if (addToBagMiddleware) {
                            addToBagMiddleware(config)
                                .then((newConfig) => {
                                    addToBag(newConfig);
                                })
                                .catch(console.error);
                        } else {
                            addToBag(config);
                        }
                    }}
                    disabled={isAddingToBag}
                >
                    {isAddingToBag
                        ? "Adding..."
                        : `Add to Bag${
                              doSubscribe && product.meta["subscription-eligible"] === "yes"
                                  ? " & Subscribe"
                                  : ""
                          }`}
                </Button>
            </Box>

            {children}

            {showFooter && (
                <BuyBoxFooter
                    adjustedPrice={adjustedPrice}
                    freeShippingThreshold={!isFitness && !isDigital ? freeShippingThreshold : null}
                />
            )}
        </>
    );
};
export default InStockView;
