import React from "react";
import Box, { BoxProps } from "../../box/Box";
import Product from "../../../@types/interfaces/product/BuyBoxProduct";
import { Img } from "../../image";
import Text, { List, ListItem } from "../../text";
import StarRating from "../../rating/StarRating";
import PriceLabel from "../../buybox/shared/PriceLabel";
import {useAddToBag} from "../../../hooks/useAddToBag";
import Button from "../../button/Button";
import {ProductNameTransform} from "../../buybox/BuyBox";
import {FavoriteContainerButton} from "../../buybox/shared/favorites";

interface Props extends BoxProps {
    product: Product;
    productNameTransform?: ProductNameTransform;
    additionalImgixParams?: string;
    showBenefits?: boolean;
}

const SimpleBuyBox: React.FC<React.PropsWithChildren<Props>> = ({ product, productNameTransform, additionalImgixParams, showBenefits = false, children, ...rest }) => {
    const { isAddingToBag, addToBag } = useAddToBag();

    if (!product) {
        return null;
    }

    return (
        <>
            <Box position="relative" display="flex" flexDirection="column" height="100%"
                 style={product.in_stock ? {} : {opacity: 0.65}} {...rest}>
                {!product.in_stock && (
                    <Box display="flex" justifyContent="flex-end" mr={2} position="absolute" right={0}>
                        <Box style={{background: "rgba(255,255,255,0.7)", border: '1px solid #353535'}} py={[1]}
                             px={[1]}>
                            <Text textTransform="uppercase" fontWeight="bold" color="grays.6" fontSize={0}>Out of
                                Stock</Text>
                        </Box>
                    </Box>
                )}
                <Img
                    src={`https://onnits3.imgix.net/authority/${product.meta["authority-id"]}.png?w=704&q=50&fm=pjpg&auto=compress,format&${additionalImgixParams}`}
                    placeholder={[704, 958]}
                    alt="product image"
                />

                <Box flex="1" mb={2}>
                    <Text typeStyle="text03" as="p" mb={2}>
                        {productNameTransform ? productNameTransform(product.name, product.id) : product.name}
                    </Text>
                    {product.blurb && (
                        <Text typeStyle="text04" fontWeight="bold" as="p" mt={2} color="grays.5">
                            {product.blurb}
                        </Text>
                    )}
                    {showBenefits && (
                        <List>
                            {product.product_benefits.map((benefit, index) => (
                                <ListItem key={index} typeStyle="text04" color="grays.6" textTransform="capitalize">
                                    {benefit}
                                </ListItem>
                            ))}
                        </List>
                    )}
                    {children}
                </Box>

                <Box display="flex" flex={product.in_stock ? "0" : "1"}>
                    <Box flex="1">
                        {product.bazaarvoiceRating && (
                            <StarRating rating={Number(product.bazaarvoiceRating)} mb={3} />
                        )}
                        <PriceLabel product={product} />
                    </Box>
                    <Box>
                        <FavoriteContainerButton
                            productId={product.id}
                            iconOnly={true}
                            size="small"
                            width="initial"
                        />
                    </Box>
                </Box>

                {product.in_stock && (
                    <Button
                        size={["small", "small", "medium"]}
                        mt={5}
                        width={1}
                        display="block"
                        disabled={isAddingToBag}
                        onClick={() => {
                            const config = {
                                product_id: product.id,
                                quantity: 1,
                                subscription_interval: null,
                            };

                            addToBag(config);
                        }}
                    >
                        {isAddingToBag ? "Adding..." : `Add to Bag`}
                    </Button>
                )}
            </Box>
        </>
    );
};

export default SimpleBuyBox;
