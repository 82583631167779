import React, { useCallback, ReactNode, useRef, useState } from "react";
import Text from "../../../text/Text";
import NewCustomerRegistrationForm from "../../../form/NewCustomerRegistrationForm";
import Modal from "../../../modal/Modal";
import FavoritesBrowserStorageService from "./FavoritesBrowserStorageService";
import { useFavorites } from "./useFavorites";

interface Props {
    addProduct: (productId: number) => Promise<void>;
    removeProduct: (productId: number) => Promise<void>;
    isProductOnFavorites: (productId: number) => boolean;
}

type RenderFn = (x: Props) => ReactNode;

const FavoriteContainer = ({ children }: { children: RenderFn }) => {
    const { addFavorite, removeFavorite, isProductOnFavorites } = useFavorites();
    const [customerId, setCustomerId] = useState<number | undefined>(
        FavoritesBrowserStorageService.getCustomerId()
    );
    const [isGuestModalOpen, setGuestModalOpen] = useState(false);
    const [customerCreated, setCustomerCreated] = useState(false);
    const promise = useRef<any>();

    const addProduct = useCallback(
        async (productId: number): Promise<void> => {
            if (!customerId) {
                try {
                    const cid = await openModal();
                    return addFavorite(cid as number, productId);
                } catch (e) {
                    throw e;
                } finally {
                    promise.current = undefined;
                }
            }
            return addFavorite(customerId as number, productId);
        },
        [customerId, addFavorite]
    );

    const removeProduct = useCallback(
        async (productId: number): Promise<void> => {
            if (!customerId) {
                try {
                    const cid = await openModal();
                    return removeFavorite(cid as number, productId);
                } catch (e) {
                    throw e;
                } finally {
                    promise.current = undefined;
                }
            }
            return removeFavorite(customerId as number, productId);
        },
        [customerId, removeFavorite]
    );

    const openModal = async () => {
        setGuestModalOpen(true);
        return new Promise((resolve, reject) => {
            promise.current = { resolve, reject };
        });
    };

    const closeModal = () => {
        setGuestModalOpen(false);
        promise?.current?.reject();
    };

    const handleNewCustomerSuccess = () => {
        const cid = FavoritesBrowserStorageService.getCustomerId();
        if (cid) {
            setCustomerId(cid);
            promise?.current?.resolve(cid);
            setCustomerCreated(true);
        }
    };

    return (
        <>
            {children({ addProduct, removeProduct, isProductOnFavorites })}

            <Modal
                title="Add to favorites"
                aria-describedby="favoritesModalDescription"
                isOpen={isGuestModalOpen}
                onRequestClose={closeModal}
            >
                {!customerCreated && (
                    <>
                        <Text as="p" mb={2} display="block" id="favoritesModalDescription">
                            Sign up now to add this item to your favorites.
                        </Text>

                        <Text as="p" mb={5} display="block">
                            Already have an account?{" "}
                            <a
                                href={`/cart/logoff.php?next=${encodeURIComponent(window.location.pathname)}`}
                                style={{ fontWeight: "bold", textDecoration: "underline" }}
                            >
                                Sign in
                            </a>
                        </Text>
                    </>
                )}

                <NewCustomerRegistrationForm
                    includeEmailConfirmation={false}
                    includePasswordFields={false}
                    successMessage="Your account was successfully created and this item was saved to your favorites."
                    onSuccess={handleNewCustomerSuccess}
                    onClose={closeModal}
                    location="Add Favorite Modal"
                />
            </Modal>
        </>
    );
};

export default FavoriteContainer;
