import {StarTestimonial} from "../../components/common/FiveStarTestimonials";

const testimonials: StarTestimonial[] = [
    {
        quote: "Great product. The day pack keeps me energized and focused all day. The night one helps me get a great night’s sleep. So I can be recharged and ready to crush it at the gym at 5 a.m.",
        author: "Santos",
        starsColor: "#d1b16e"
    },
    {
        quote: 'I began an intense running routine after not running in years. That paired with a full time job, strength training, and working on music. I really think Total Human<span class="oui-sup">®</span> has allowed me to recover so I’m not too tired to work, and the Alpha BRAIN<span class="oui-sup">®</span> in the daytime pack has kept me upright and productive all day. Thank you Onnit!',
        author: "Frank",
        starsColor: "#d1b16e"
    },
    {
        quote: "Incredible product. Right from the start I noticed a huge difference in how I felt overall. Lots of focus and energy during the day and great sleep all night long!",
        author: "Lillith",
        starsColor: "#d1b16e"
    },
    {
        quote: 'I have to say, a month in and I really love this! It’s helped get me energized in the morning and wind me down at night. Along with making healthy choices, I’d say that Total Human<span class="oui-sup">®</span> is just what I was looking for! I plan on adding other Onnit supplements for even better performance!',
        author: "Mandy",
        starsColor: "#d1b16e"
    },
    {
        quote: "This may be the single most impactful supplement that I have used to date.  I've only used it for about a week and I feel like it has really helped  my recovery from hard training!  I've also noticed that it helps my stress  levels. I seem to be more calm and focused.",
        author: "Sarah",
        starsColor: "#d1b16e"
    },
    {
        quote: "This may seem a little pricey, but it is all worth it!! The day time and night time mix is great. I noticed a well-balanced energy throughout the day and GREAT sleep at night. I’m good with taking pills, so this was easy and quick for me.",
        author: "John",
        starsColor: "#d1b16e"

    },
];

export default testimonials;
