import React, {ReactNode, useRef, useState} from "react";
import styled, {css} from "styled-components";
import Box, {BoxProps} from "../box/Box";
import Text from "../text";
import Icon from "../icon/Icon";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import Media from "@onnit-js/ui/components/media";
import {CSSTransition, SwitchTransition, TransitionGroup} from "react-transition-group";
import IndexIcon from "../shared/IndexIcon";
import {theme} from "styled-tools";
import {Img} from "../image";

export interface MediaContentShowcaseItem {
    image: string;
    title: string;
    summary: string;
    detail: string;
    alt?: string;
    imageWidth: string | number;
    imageHeight: string | number;
}

interface Props extends BoxProps {
    textColor?: string;
    activeColor?: string;
    activeTextColor?: string;
    bgMedia?: ReactNode;
    showSlideIndex?: boolean;
    slideIndexPosition?: "left" | "right";
    items: MediaContentShowcaseItem[];
    lazyLoad?: boolean;
    showImages?: boolean;
}

const duration = 400;

const transitions = css`
    .item-enter {
        opacity: 0;
    }

    .item-enter-active {
        opacity: 1;
        transition: opacity ${duration}ms ease-in;
    }

    .item-exit {
        opacity: 1;
    }

    .item-exit-active {
        opacity: 0;
        transition: opacity ${duration}ms ease-in;
    }
`;

const Grid = styled(Box)`
    max-width: ${theme("gridMaxWidth")}px;
    margin-left: auto;
    margin-right: auto;
    user-select: none;
    ${transitions};

    .mcs_tg {
        position: relative;
        width: 100%;
    }

    .mcs__titles,
    .mcs__col1,
    .mcs__col2 {
        padding: 16px 24px;
        position: relative;
    }

    .mcs__col1,
    .mcs__col2 {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        > div {
            grid-column: 1 / -1;
            grid-row: 1 / -1;
        }
    }

    @media (min-width: ${(p) => p.theme.breakpoints[1]}) {
        display: grid;
        grid-template-columns: repeat(12, 1fr);

        .mcs__titles,
        .mcs__col1,
        .mcs__col2 {
            grid-row: 2;
            grid-column-end: span 4;
            padding: 24px;
        }

        .mcs__titles {
            grid-column-start: 1;
        }

        .mcs__col1 {
            grid-column-start: 5;
        }

        .mcs__col2 {
            grid-column-start: 9;
        }
    }
    @media (min-width: ${(p) => p.theme.breakpoints[2]}) {
        .mcs__titles,
        .mcs__col1,
        .mcs__col2 {
            grid-row: 2;
            grid-column-end: span 2;
            padding: 24px 24px 24px 0;
        }

        .mcs__titles {
            grid-column-end: span 3;
            grid-column-start: 3;
        }

        .mcs__col1 {
            grid-column-start: 6;
        }

        .mcs__col2 {
            grid-column-start: 9;
            grid-column-end: span 3;
        }
    }
`;

const Container = styled(Box)`
    position: relative;
    grid-column: 1 / -1;
    @media (min-width: ${(p) => p.theme.breakpoints[2]}) {
        grid-column: 3 / span 7;
    }
    grid-row: 1;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr min-content 1fr;
`;

const Showcase = styled(Box)`
    grid-row: 1/ -1;
    grid-column: 1 / -1;
    overflow: hidden;
`;

const MediaList = styled(Box)`
    position: relative;
    display: grid;
    grid-auto-columns: 100%;
    transition: transform ${duration}ms cubic-bezier(0, 0, 0, 1);
    backface-visibility: hidden;
    z-index: 2;
`;

const MediaItem = styled(Box)<{ isActive: boolean }>`
    grid-row: 1 / span 1;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 50% !important;
        display: block;
        opacity: ${(p) => (p.isActive ? 1 : 0)};
        transition: opacity 1000ms;
    }
`;

const BgImage = styled.div`
    grid-column: 1 / -1;
    grid-row: 2 / span 1;
    z-index: 1;

    img,
    video {
        width: 100%;
        display: block;
    }
`;

function MediaContentShowcase({
                                  items,
                                  bgMedia,
                                  textColor = "grays.4",
                                  activeColor = "black",
                                  activeTextColor,
                                  showSlideIndex = true,
                                  slideIndexPosition = "right",
                                  lazyLoad = true,
                                  showImages = true,
                                  ...rest
                              }: Props) {
    const [currentIndex, setIndex] = useState(0);
    const nodeRef = useRef(null);
    const nodeRef2 = useRef(null);
    const showDots = showSlideIndex && items.length > 1;

    function handlePrev() {
        if (currentIndex > 0) {
            setIndex((i) => i - 1);
        }
    }

    function handleNext() {
        if (currentIndex < items.length - 1) {
            setIndex((i) => i + 1);
        }
    }

    return (
        <Grid {...rest}>
            <Container>
                {bgMedia && <BgImage>{bgMedia}</BgImage>}

                {showImages && (
                    <Showcase>
                        <MediaList
                            style={{
                                transform: `translateX(${currentIndex * -100}%)`,
                            }}
                        >
                            {items.map((item, i) => (
                                <MediaItem
                                    key={i}
                                    gridColumn={i + 1}
                                    isActive={currentIndex === i}
                                    aria-hidden={currentIndex !== i}
                                >
                                    <Img
                                        alt={item.alt ? item.alt : item.title}
                                        src={item.image}
                                        lazyLoad={lazyLoad}
                                        placeholder={[item.imageWidth, item.imageHeight]}
                                        width={item.imageWidth}
                                        height={item.imageHeight}
                                    />
                                </MediaItem>
                            ))}
                        </MediaList>
                    </Showcase>
                )}

                {showImages && showDots && (
                    <Box
                        display={["none", "flex"]}
                        gridRow="3"
                        gridColumn={slideIndexPosition === "right" ? -2 : 1}
                        justifyContent={slideIndexPosition === "right" ? "flex-end" : "flex-start"}
                        alignItems="flex-start"
                        py={4}
                        px={2}
                        zIndex={10}
                    >
                        {items.map((item, i) => (
                            <IndexIcon
                                key={i}
                                color={currentIndex === i ? activeColor : textColor}
                                active={i === currentIndex}
                                onClick={() => setIndex(i)}
                                onKeyUp={(evt: KeyboardEvent) => evt.key === "Enter" && setIndex(i)}
                                tabIndex={0}
                                role="button"
                                aria-label={item.title}
                                style={{cursor: "pointer"}}
                            />
                        ))}
                    </Box>
                )}
            </Container>

            <Box display={["flex", "none"]} justifyContent="space-between">
                <Icon
                    size={42}
                    m={4}
                    icon={IoIosArrowBack}
                    color={textColor}
                    onClick={() => handlePrev()}
                    ariaLabel="Previous"
                    style={{opacity: currentIndex !== 0 ? 1 : 0.2}}
                />
                <Icon
                    size={42}
                    m={4}
                    icon={IoIosArrowForward}
                    color={textColor}
                    onClick={() => handleNext()}
                    ariaLabel="Next"
                    style={{opacity: currentIndex !== items.length - 1 ? 1 : 0.2}}
                />
            </Box>

            <Box className="mcs__titles">
                <Media query="(min-width: 640px)">
                    {(matches) =>
                        matches ? (
                            items.map((item, i) => (
                                <Text
                                    key={i}
                                    color={currentIndex === i ? activeColor : textColor}
                                    display="block"
                                    typeStyle="copy05"
                                    mb={4}
                                >
                                    <span
                                        tabIndex={0}
                                        role="button"
                                        onClick={() => setIndex(i)}
                                        onKeyUp={(e) => e.key === "Enter" && setIndex(i)}
                                        style={{cursor: "pointer"}}
                                        dangerouslySetInnerHTML={{
                                            __html: item.title,
                                        }}
                                    />
                                </Text>
                            ))
                        ) : (
                            <Text
                                as="p"
                                color={activeColor}
                                display="block"
                                typeStyle="copy05"
                                dangerouslySetInnerHTML={{
                                    __html: items[currentIndex].title,
                                }}
                            />
                        )
                    }
                </Media>
            </Box>

            <div className="mcs__col1">
                <SwitchTransition>
                    <CSSTransition nodeRef={nodeRef} key={currentIndex} timeout={duration} classNames="item">
                        <Box>
                            <Text as="p" color={activeColor} display="block" typeStyle="copy05" mb={2}>
                                What it is
                            </Text>

                            <Text
                                ref={nodeRef}
                                as="p"
                                color={activeTextColor ?? textColor}
                                display="block"
                                lineHeight={6}
                                fontWeight="light"
                                fontSize={[2, 2, 3]}
                                dangerouslySetInnerHTML={{
                                    __html: items[currentIndex].summary,
                                }}
                            />
                        </Box>
                    </CSSTransition>
                </SwitchTransition>
            </div>

            <div className="mcs__col2">
                <SwitchTransition>
                    <CSSTransition nodeRef={nodeRef2} key={currentIndex} timeout={duration} classNames="item">
                        <Box>
                            <Text as="p" color={activeColor} display="block" typeStyle="copy05" mb={2}>
                                What it does
                            </Text>

                            <Text
                                ref={nodeRef2}
                                as="p"
                                color={activeTextColor ?? textColor}
                                display="block"
                                lineHeight={6}
                                fontWeight="light"
                                fontSize={[2, 2, 3]}
                                dangerouslySetInnerHTML={{
                                    __html: items[currentIndex].detail,
                                }}
                            />
                        </Box>
                    </CSSTransition>
                </SwitchTransition>
            </div>
        </Grid>
    );
}

export default MediaContentShowcase;
