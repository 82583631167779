import React from "react";
import { TextList } from "../../interfaces/SiteContent";
import Text from "@onnit-js/ui/components/text";
import Box from "@onnit-js/ui/components/box/Box";

interface Props {
    data: TextList;
}

const ContentfulTextList = ({ data }: Props) => {
    if (!data.items || data.items.length === 0) {
        return null;
    }
    return (
        <Box as="ul" maxWidth={["100%", "100%", "364px", "664px"]} mx="auto">
            {data.items.map((item, index) => {
                return (
                    <Box
                        key={index}
                        as="li"
                        bg="white"
                        py={4}
                        px={[4, 4, 4, 8]}
                        boxShadow="inset 0 0 5px rgba(0,0,0,0.4)"
                        border="1px solid white "
                        borderRadius="8px"
                        mb={4}
                    >
                        <Text typeStyle="text02" color="plateGray" textAlign={["left", "left", "left", "center"]}>
                            {item}
                        </Text>
                    </Box>
                );
            })}
        </Box>
    );
};

export default ContentfulTextList;
