import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Box, { BoxProps } from "../../box/Box";
import IndexIcon from "../../shared/IndexIcon";
import { useSpring, animated, interpolate } from "react-spring/web.cjs";
import { useDrag } from "@use-gesture/react";

interface Props extends BoxProps {
    currentIndex: number;
    updateIndex: (i: number) => void;
    incrementIndex: Function;
    decrementIndex: Function;
}

const Container = styled(Box)`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: min-content 1fr;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .hide-ie {
            display: none !important;
        }
    }
`;

const Showcase = styled(Box)`
    grid-row: 1 / span 1;
    grid-column: 1 / -1;
    overflow: hidden;
`;

const MediaList = styled(Box)`
    touch-action: pan-x;
    position: relative;
    display: grid;
    grid-auto-columns: 100%;
    backface-visibility: hidden;
    z-index: 1;
    .dashContainer {
        display: none;
    }
`;

const AnimatedMediaList = animated(MediaList);

export const Mobile: React.FC<React.PropsWithChildren<Props>> = ({
    currentIndex,
    updateIndex,
    incrementIndex,
    decrementIndex,
    children,
    ...rest
}: Props) => {
    const minIndex = 1;
    const maxIndex = React.Children.count(children) - 1;
    const firstRender = useRef(true);

    //animated values
    const [{ translateX, dragX }, set] = useSpring(() => ({
        translateX: 0,
        dragX: 0
    }));

    //drag config.
    const bind = useDrag(({ movement, last, velocity }) => {
        const DRAG_THRESHOLD = Math.floor(window.innerWidth / 3);
        // this is the drag value
        const [x] = movement;

        // the velocity of the drag -- important to track to prevent jank after user releases
        const [vx] = velocity;

        // we want the value to immediate update w/ a user drag event, not spring to the value
        set({ dragX: x, immediate: true });

        // last is true when the user releases from dragging
        if (last) {
            // user has dragged beyond our threshold to transition (either left or right)
            let shouldTransition = Math.abs(x) >= DRAG_THRESHOLD;

            if (!shouldTransition) {
                // restore to initial position when user started dragging:
                set({ dragX: 0, immediate: false });
            } else {
                // determine the next position based on the drag value (left or right)
                let nextPosition;
                if (x > DRAG_THRESHOLD) {
                    const clampedMax = Math.min(maxIndex, currentIndex + 1);
                    // transition to next page
                    nextPosition = clampedMax * -100 + 100;
                    // update our controller component w/ the previous index
                    incrementIndex();
                }

                if (x < DRAG_THRESHOLD) {
                    const clampedMin = Math.max(minIndex, currentIndex - 1);
                    // transition to previous page
                    nextPosition = clampedMin * -100 - 100;
                    // update our controller component w/ the next index
                    decrementIndex();
                }

                // start spring transition to next position
                // we want to spring the drag value back to 0 as we translate to the next position
                set({
                    dragX: 0,
                    translateX: nextPosition,
                    immediate: false,
                    config: {
                        velocity: vx
                    }
                });
            }
        }
    });

    //animate if the currentIndex prop changes
    useEffect(() => {
        set({ translateX: currentIndex * 100 * -1, immediate: firstRender.current });
        firstRender.current = false;
    }, [currentIndex, set]);

    return (
        <Container {...rest}>
            <Showcase>
                <AnimatedMediaList
                    {...bind()}
                    style={{
                        transform: interpolate(
                            [
                                translateX as any,
                                dragX as any
                            ],
                            (translateX, dragX) => `translateX(calc(${translateX}% + ${dragX}px))`
                        )
                    }}>
                    {React.Children.map(children, (child, i) => (
                        <Box key={i} gridColumn={i + 1}>
                            {child}
                        </Box>
                    ))}
                </AnimatedMediaList>
            </Showcase>
            <Box
                className="hide-ie"
                gridColumn={["1 / -1", "1 / -1", "2 / span 10"]}
                gridRow="2"
                ml={[0, 0, "40%"]}
                display={["flex", "flex", "block"]}
                px={6}
                justifyContent="center">
                {React.Children.map(children, (child, i) => (
                    <IndexIcon
                        key={i}
                        active={i === currentIndex}
                        onClick={() => {
                            updateIndex(i);
                        }}
                        style={{ cursor: "pointer" }}
                    />
                ))}
            </Box>
        </Container>
    );
};
