export function makePseudoButtonProps(callback: Function): Object {
    function open(e: any) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "click" || (e.type === "keyup" && e.key === "Enter")) {
            callback();
        }
        return false;
    }

    return {
        onClick: open,
        onKeyUp: open,
        onKeyDown: (e: any) => {
            e.stopPropagation();
        },
        role: "button",
        style: { cursor: "pointer" },
        tabIndex: 0,
    };
}
