import Product from "../../@types/interfaces/product/BuyBoxProduct";
import {ProductStatus} from "../../@types/enums/ProductStatus";

export function getProductStatus(product: Product): ProductStatus {
    if (product.in_stock) {
        return ProductStatus["InStock"];
    }

    return product.discontinued ? ProductStatus["Discontinued"] : ProductStatus["OutOfStock"];
}
